import React, { PureComponent } from 'react'
import { connect, FormikContextType } from 'formik'
import * as R from 'ramda'

import Text from 'lib/components/Text'
import { utils } from '@ims/1edtech-frontend-common'

interface IValues {
  [s: string]: any
}
interface IFormikProps {
  formik: FormikContextType<IValues>
}
interface IOuterProps {
  name: string
  showImmediately?: boolean
  showRequiredOnlyOnSumbit?: boolean
  showIfHasValue?: boolean
}
export class FormErrorMessage extends PureComponent<
  IFormikProps & IOuterProps
> {
  render() {
    const {
      formik: { submitCount, values, touched, errors },
      name,
      showImmediately = false,
      showRequiredOnlyOnSumbit = false,
      showIfHasValue = false,
    } = this.props

    const hasSubmitted = submitCount > 0
    const value = R.propOr<string, any, string>('', name, values)
    const error = R.propOr<string, any, string>('', name, errors)
    const hasBeenTouched = R.prop(name, touched)
    const shouldShow = showImmediately
      ? true
      : showRequiredOnlyOnSumbit &&
        (!error.toLowerCase().includes('required') || hasSubmitted)
      ? true
      : showIfHasValue && utils.hasValue(value) && hasBeenTouched
      ? true
      : hasBeenTouched

    if (utils.hasValue(error) && (shouldShow || hasSubmitted)) {
      return (
        <Text variant="formError" data-test={`form-error-${name}`}>
          {error}
        </Text>
      )
    }
    return null
  }
}

export default connect<IOuterProps>(FormErrorMessage)
