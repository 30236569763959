import React from 'react'

type Props = {
  htmlFor: string
  children: any
  required?: boolean
  extra?: any
}

export default function FormLabel(props: Props) {
  return (
    <label
      htmlFor={props.htmlFor}
      className="text-text font-bold text-gray-700 flex flex-row items-center text-lg"
    >
      {props.children}
      {props.required && <span className="text-error ml-0.5">*</span>}
      {props.extra}
    </label>
  )
}
