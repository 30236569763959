import {
  TRUSTED_APPS_PRIOTIRY_MAP,
  TrustedApplicationStatusesType,
} from 'domains/trustedApps/models/ITrustedApplication'
import { getRequest } from 'lib/api/api'
import { useEffect, useState } from 'react'

interface IPublicApplication {
  logo?: string
  privacyUrl?: string
  status: TrustedApplicationStatusesType
  title: string
}

interface UseQueryPublicApplicationsOptions {
  orgId: string | number
}

const sortByStatus = (apps: IPublicApplication[]) => {
  return [...apps].sort(
    (a, b) =>
      TRUSTED_APPS_PRIOTIRY_MAP[a.status] - TRUSTED_APPS_PRIOTIRY_MAP[b.status],
  )
}

export function useQueryPublicApplications({
  orgId,
}: UseQueryPublicApplicationsOptions) {
  const [loading, setLoading] = useState(false)
  const [applications, setApplications] = useState<IPublicApplication[]>([])

  useEffect(() => {
    setLoading(true)

    getRequest(`trustedapps/public/organizations/${orgId}/applications`)
      .then((res) => sortByStatus(res.data.applications))
      .then((apps) => setApplications(apps))
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }, [orgId])

  return { data: applications, loading }
}
