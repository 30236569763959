import { useQueryPublicApplications } from 'domains/trustedApps/hooks/useQueryPublicApplications'
import {
  TRUSTED_APP_STATUS_COLOR_MAP,
  TRUSTED_APP_STATUS_ICON_MAP,
} from 'domains/trustedApps/models/ITrustedApplication'
import { Icon, Spinner, Text } from 'lib/components'
import React from 'react'
import { useParams } from 'react-router-dom'

const EmbeddableTrustedAppsScreen = () => {
  const params = useParams<{ orgId: string }>()
  const { loading, data } = useQueryPublicApplications({ orgId: params.orgId })

  if (loading) {
    return (
      <div className="flex align-center justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-2 py-3 px-3 bg-white">
      {data.map((app, index) => {
        const icon = TRUSTED_APP_STATUS_ICON_MAP[app.status]

        return (
          <div
            key={index}
            className="flex items-center gap-3 py-2 px-2 shadow-md"
          >
            <Icon
              className={icon}
              color={TRUSTED_APP_STATUS_COLOR_MAP[app.status]}
              fontSize={48}
              width={48}
            />

            <img
              className="w-12 h-12 text-xs max-h-full object-contain"
              src={app.logo}
              alt={`${app.title} logo`}
            />

            <Text className="text-lg font-bold ml-2">{app.title}</Text>

            <a
              href={app.privacyUrl}
              target="_blank"
              rel="noreferrer"
              className="ml-2"
            >
              Privacy Policy
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default EmbeddableTrustedAppsScreen
