const primary = '#2a2867'
const secondary = '#ff941f'
const tertiary = '#57afe2'
const complete = '#3333FF'
const background = '#F6F7FA'
const locked = '#063771'

const text = '#2d2f2f'
const textLight = '#6c757d6c757d'
const neutral = '#c2c2c2'
const textHighlight = tertiary

const colors = {
  primary,
  secondary,
  complete,
  tertiary,
  background,
  locked,
  error: '#A32C00',
  errorLight: '#fed7d9',
  errorAlt:'#E57800',
  start: '#6666FF',
  paused: secondary,
  success: '#0E6A31',
  gold: '#FFBF00',
  navy: '#9999FF',
  warning: '#FFCA29',
  warningAlt: '#E9821E',
  info: tertiary,
  infoLight: '#6DCFF6',
  infoAlert: '#fffcda',
  focusShadow: 'rgba(0,123,255,.25)',
  white: '#fff',
  neutral,
  builtInMetaDataBG: '#dde7f5',
  customMetaDataBG: '#fffcda',
  grey:'#2F2F2F',

  reportSuccessLight: '#daf2e4',
  reportAmberLight: '#fbe6d3',
  reportAlertLight: '#fdcbcd',
  reportTableBorder: '#ebebec',
  reportRowDivider: '#676767',
  reportDisabled: '#6F7071',

  // Font colors
  text,
  textLight,
  textHighlight,
  placeholder: '#a3a3a3',

  chartColors: [
    primary,
    secondary,
    '#F564A9',
    '#242038',
    complete,
    '#5CC8FF',
    '#FAA4BD',
    '#FAE3C6',
    '#725AC1',
    '#8D86C9',
    '#EE9480',
    '#7D70BA',
  ],

  activeTabColor: '#e1e1e1',
  inactiveTabColor: tertiary,
  neutralTabColor: '#707070',

  ccReportBlue900: '#E3E7F3',
  ccReportBlue700: '#CDD3EB',
  ccReportBlue500: '#5280A4',
  ccReportPeach900: '#BCAEAB',
  ccReportPeach700: '#FEECE8',
  ccReportItemTitleBG: '#bce6f8',
  ccReportItemBG: '#E6F7FE',
  ccReportStandardTitleBG: '#e1d8d1',
  ccReportStandardBG: '#F6F1ED',
  ccReportLOMTitle: '#B7B7B7',
  ccReportLOMDetail: '#E7E7E7',
  ccReportMetadata: '#F0F0F0',
  ccReportResourceTitleBG: '#D3DDD2',
  ccReportResourceBG: '#EFF6EE',
  ccReportResourceTitle: '#0D5C2E',

  'ta-gray-1': '#c6c6c6',
  'ta-gray-2': '#aaaaaa',
  'ta-gray-3': '#747474',
  'ta-gray-4': '#636060',
  'ta-blue-1': '#98bbff',
  'ta-blue-2': '#6699ff',
  'ta-blue-3': '#4769ad',
  'ta-blue-4': '#1B7CDD',
  'ta-blue-5': '#509BD8',

  'ta-titlebar': '#E9EBED',
  'ta-approved-limited-purple': '#4C236C',
  'ta-under-review-orange': '#EAAD5D',
}

module.exports = colors
