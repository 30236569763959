import React, { MouseEvent } from 'react'

import Badge from 'lib/components/Badge'
import Icon from 'lib/components/Icon'

interface IProps {
  onClick: (item?: any) => any
  item?: any
  'data-test'?: string
  'aria-label'?: string
}

const DeleteCircle = (props: IProps) => {
  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    props.onClick(props.item)
  }

  return (
    <Badge
      variant="error"
      badgeType="circle"
      width={24}
      height={24}
      p={0}
      onClick={onClick}
      data-test={props['data-test']}
      aria-label={props['aria-label']}
    >
      <Icon pt="1px" color="white" className="fas fa-times" />
    </Badge>
  )
}

export default DeleteCircle
