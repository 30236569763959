import React from 'react'

import IIMSApplicationVetting, {
    VettingComplianceTypes,
    VETTING_COMPLIANCE_TITLE_MAP,
} from 'domains/trustedApps/models/IIMSApplicationVetting'
import { utils } from '@ims/1edtech-frontend-common'
import VettingSectionHeader from 'domains/trustedApps/components/TrustedAppVettings/VettingSectionHeader'
import TrustedAppLegendKey from 'domains/trustedApps/components/TrustedAppVettings/TrustedAppLegendKey'

interface IComplianceProps {
    highlight?: string
    title: string
    compliant: VettingComplianceTypes
}

const COMPLIANCE_ICON_MAP: { [key: string]: string } = {
    U: 'fas fa-exclamation-triangle',
    N: 'fas fa-circle',
    Y: 'fas fa-check',
    X: '',
}
const COMPLIANCE_COLOR_MAP: { [key: string]: string } = {
    U: 'text-warningAlt',
    N: 'text-error',
    Y: 'text-success',
    X: '',
}

function Compliance(props: IComplianceProps) {
    const titleWithHighlight = (
        <>
            {props.title.substr(0, props.title.indexOf('@@'))}
            <span className="font-bold text-complete break-words">
                {props.highlight}
            </span>
            {props.title.substr(props.title.indexOf('@@') + 2)}
        </>
    )
    const dataTest = `compliance-${props.highlight || props.title}`
    return (
        <div className="bg-gray-100 flex flex-row items-center hover:shadow">
            <div className="flex-1 m-3">
                <p className="text-lg font-light" data-test={`${dataTest}-title`}>
                    {utils.hasValue(props.highlight) ? titleWithHighlight : props.title}
                </p>
            </div>

            <div className="w-32 flex flex-col items-center justify-center h-14 border-l border-gray-400">
                {props.compliant === 'X' && (
                    <p
                        className="text-gray-500 font-bold"
                        data-test={`${dataTest}-status`}
                        data-status={props.compliant}
                    >
                        {VETTING_COMPLIANCE_TITLE_MAP['X']}
                    </p>
                )}
                {props.compliant !== 'X' && (
                    <i
                        className={`text-xl ${COMPLIANCE_ICON_MAP[props.compliant]} ${COMPLIANCE_COLOR_MAP[props.compliant]
                            }`}
                        data-test={`${dataTest}-status`}
                        data-status={props.compliant}
                    />
                )}
            </div>
        </div>
    )
}

interface IProps {
    vetting: IIMSApplicationVetting
}

export default function TrustedAppVettingCompliance(props: IProps) {
    return (
        <div className="w-full">
            <VettingSectionHeader
                title="Regulatory Compliance"
                subtitle="The information below provides insight into compliance with various
        regulatory policies."
                rightTitleContent={
                    <div className="flex flex-row items-center justify-between space-x-3 lg:space-x-6">
                        {['U', 'N', 'Y'].map((type) => (
                            <TrustedAppLegendKey
                                key={type}
                                color={COMPLIANCE_COLOR_MAP[type]}
                                icon={COMPLIANCE_ICON_MAP[type]}
                                label={VETTING_COMPLIANCE_TITLE_MAP[type]}
                            />
                        ))}
                    </div>
                }
            />

            <div className="space-y-3">
                {props.vetting.gdprCompliance && (
                    <Compliance
                        title="@@ Compliant"
                        highlight="GDPR"
                        compliant={props.vetting.gdprCompliance}
                    />
                )}
                {props.vetting.ferpaCompliance && (
                    <Compliance
                        title="@@ Compliant"
                        highlight="FERPA"
                        compliant={props.vetting.ferpaCompliance}
                    />
                )}
                {props.vetting.coppaCompliance && (
                    <Compliance
                        title="@@ Compliant"
                        highlight="COPPA"
                        compliant={props.vetting.coppaCompliance}
                    />
                )}
                {props.vetting.ageRestricted && (
                    <Compliance
                        title="Approved for children under 13"
                        compliant={props.vetting.ageRestricted}
                    />)}
                {props.vetting.accessibilityCompliance !== 'N' && (
                    <Compliance
                        title="Accessibility Statement"
                        compliant={props.vetting.accessibilityCompliance}
                    />
                )}
            </div>
        </div>
    )
}
