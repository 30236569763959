import React, { useState } from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { records } from '@ims/1edtech-frontend-common'
import clsx from 'clsx'

import { IAddTrustedAppSelection, hasAdditionalInfo } from 'domains/trustedApps/components/AddTrustedApps/models/IAddTrustedAppSelection'
import { DeleteCircle } from 'lib/components'
import { IMS_APPLICATIONS_RECORD } from 'lib/records/modules/imsApplications'
import IIMSApplication from 'domains/trustedApps/models/IIMSApplication'
import { TrustedApplicationStatusesType } from 'domains/trustedApps/models/ITrustedApplication'
import TrustedAppsStatusSelector from 'domains/trustedApps/components/AddTrustedApps/TrustedAppsStatusSelector'
import TrustedAppRowName from 'domains/trustedApps/components/AddTrustedApps/TrustedAppRowName'
import TrustedAppsAdditionalInfoCell from 'domains/trustedApps/components/TrustedAppsList/TrustedAppsAdditionalInfoCell'
import useModalState from 'lib/hooks/useModalState'
import EditAdditionalInfoModal from 'domains/trustedApps/components/EditAdditionalInfoModal'

interface IProps {
  id: number
  updateApp: (id: number, updates: any) => any
  selection: IAddTrustedAppSelection[]
  removeApp: (applicationId: number) => any
  isDuplicate?: boolean
}

export default function SelectedIMSApplicationRow(props: IProps) {
  const selected = R.find(R.propEq('applicationId', props.id))(
    props.selection,
  ) as IAddTrustedAppSelection

  const app = useSelector((s: any) =>
    records.entitiesSelectors.entityByIdSelector(IMS_APPLICATIONS_RECORD, 'id')(
      s,
      props,
    ),
  ) as IIMSApplication

  const [additionalInfo, setAdditionalInfo] = useState(false)
  const [additionalInfoOpen, openAdditionalInfo, closeAdditionalInfo] =
    useModalState()
  const onAppSaved = (app: IAddTrustedAppSelection) =>{ 
    props.updateApp(props.id, app)
    setAdditionalInfo(hasAdditionalInfo(app))
  }

  const onChangeStatus = (id: number, status: TrustedApplicationStatusesType) =>
    props.updateApp(id, { status })

  const onDelete = () => props.removeApp(props.id)

  return (
    <div
      className={clsx(
        'grid grid-row-2 lg:grid-rows-none lg:grid-cols-1fr-auto gap-1 lg:gap-2 py-2 px-2 border rounded-sm',
        {
          'border border-error': props.isDuplicate,
        },
      )}
      data-test={`selected-app-${props.id}`}
    >
      <div className="flex flex-col lg:flex-row lg:items-center space-x-2">
        <div className="flex flex-1">
          <TrustedAppRowName app={app} />
        </div>
        {props.isDuplicate && (
          <div className="flex items-center justify-center px-4 py-2 bg-error text-white rounded-full">
            <p className="text-base font-semibold">DUPLICATE</p>
          </div>
        )}
      </div>

      <div className="flex flex-row flex-wrap items-center space-x-1 lg:space-x-3">
        <div className="flex flex-col items-center justify-center">
          <TrustedAppsStatusSelector
            applicationId={props.id}
            status={selected.status}
            onStatusChange={onChangeStatus}
          />
        </div>

        <div className="mt-1 md:mt-0">
          <TrustedAppsAdditionalInfoCell
            id={app.id}
            onClick={openAdditionalInfo}
            additionalInfo={additionalInfo}
          />
        </div>

        <div className="flex flex-col items-center justify-center">
          <DeleteCircle
            onClick={onDelete}
            item={selected}
            data-test={`delete-app-${selected.applicationId}`}
            aria-label={`Delete app: ${app.productName}`}
          />
        </div>
      </div>

      <EditAdditionalInfoModal
        isOpen={additionalInfoOpen}
        onRequestClose={closeAdditionalInfo}
        app={{ ...selected, title: app.productName }}
        onAppSaved={onAppSaved}
      />
    </div>
  )
}
