import React from 'react'
import clsx from 'clsx'

import { TrustedApplicationStatusesType } from 'domains/trustedApps/models/ITrustedApplication'
import TrustedAppStatusChip from 'domains/trustedApps/components/TrustedAppDetails/TrustedAppStatusChip'
import IIMSApplication, {
  hasTrustEdAppsSeal,
} from 'domains/trustedApps/models/IIMSApplication'
import { utils } from '@ims/1edtech-frontend-common'
import TrustedAppLogo from 'domains/trustedApps/components/TrustedAppsList/TrustedAppLogo'
import TrustEdAppsSeal from 'domains/trustedApps/components/TrustEdAppsSeal'
import OrgMembershipLevelChip from 'domains/orgs/components/OrgMembershipLevelChip'

interface IProps {
  app: IIMSApplication
  status?: TrustedApplicationStatusesType
  rightIcon?: any
  rightTitle?: string
}

export default function TitleBar(props: IProps) {
  return (
    <div className="bg-ta-titlebar flex flex-row flex-wrap lg:flex-nowrap justify-between">
      <div className="flex flex-row items-center p-4">
        <TrustedAppLogo
          title={props.app.productName}
          logo={props.app.productImage}
        />
        <div className="ml-4">
          <h1 className="text-2xl font-bold text-black" data-test="app-name">
            {props.app.productName}
          </h1>
          <p className="text-xs text-textLight" data-test="app-org">
            By {props.app.orgName}
          </p>
        </div>
      </div>

      <div
        className={clsx(
          'flex flex-col lg:flex-row items-center lg:w-auto',
          'space-y-1 lg:space-y-0 space-x-0 lg:space-x-3 mt-3 lg:mt-0',
        )}
      >
        {hasTrustEdAppsSeal(props.app) && (
          <TrustEdAppsSeal className="w-10 h-10" />
        )}

        {utils.hasValue(props.status) && (
          <div className="px-3 rounded-full bg-white">
            <TrustedAppStatusChip
              status={props.status || 'DENIED'}
              data-test="trusted-app-status"
            />
          </div>
        )}

        {utils.hasValue(props.app.orgMemberType) && (
          <OrgMembershipLevelChip membershipType={props.app.orgMemberType} />
        )}

        {utils.hasValue(props.rightTitle) && (
          <div
            className="flex flex-row items-center justify-center lg:justify-start p-3 h-full w-full lg:w-auto"
            style={{ background: '#848687' }}
          >
            {props.rightIcon}
            <p className="text-xl text-white ml-3">{props.rightTitle || ''}</p>
          </div>
        )}
      </div>
    </div>
  )
}
