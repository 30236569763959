import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'

import { Screen, Spinner } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import TrustedAppsConnectionList from 'domains/trustedApps/components/TrustedAppsConnectionList'
import TrustedAppsAdditionalInfoWidget from 'domains/trustedApps/components/TrustedAppsAdditionalInfoWidget'
import useRecordEntity from 'lib/records/hooks/useRecordEntity'
import { TRUSTED_APPS_DETAILS_RECORD } from 'lib/records/modules/trustedAppsDetails'
import ITrustedAppsDetails from 'domains/trustedApps/models/ITrustedAppsDetails'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import TrustedAppsLockedConnectionWidget from 'domains/trustedApps/components/TrustedAppsLockedConnectionWidget'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import { DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE } from 'domains/application/navigation/routes'

import { getRecords } from 'lib/records/workflows/getRecords'
import { LTI_CONFIGURATIONS_RECORD } from 'lib/records/modules/ltiConfigurations'
import ILTIConfiguration from 'domains/trustedApps/models/ILTIConfiguration'

const WIDGET_HEIGHT = 896

export default function TrustedAppsSetupScreen() {
    const orgId = useSelector((s: any) => myOrganizationIdSelector(s, {}))
    const [org] = useRecordEntity({ record: ORGANIZATIONS_RECORD, id: orgId })
    const trustedAppsLtiEnabled = R.pathOr(
        false,
        ['trustedAppsLtiEnabled'],
        org,
    )
    const [details, detailsPending] = useRecordEntity({
        record: TRUSTED_APPS_DETAILS_RECORD,
        id: 1,
    })

    const [ltiConfigurations, setLtiConfigurations] = React.useState<
        ILTIConfiguration[]
    >([])

    const fetchConfigs = async () => {
        await getRecords({
            record: LTI_CONFIGURATIONS_RECORD,
            parentRecordId: orgId,
            replaceItems: true,
            force: true,
            page: 1,
            pageSize: 16
        }).then((res) => {
            res && setLtiConfigurations(res.data.configurations)
        })
    }

    React.useEffect(() => {
        fetchConfigs()
    }, []) //eslint-disable-line

    const pageDetails = (
        <>
            <DocumentTitle title="TrustEd Apps - Setup" />
            <BaseBreadCrumbs
                crumbs={[
                    {
                        name: 'TrustEd Apps',
                        route: DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE,
                    },
                    { name: 'Setup' },
                ]}
            />
        </>
    )
    if (detailsPending) {
        return (
            <Screen>
                {pageDetails}
                <Spinner centered={true} />
            </Screen>
        )
    }

    const trustedAppsDetails: ITrustedAppsDetails = details || {
        suggestedAppRecipientEmail: '',
        welcomeText: '',
        teacherShowTAView: false,
        taHideAppDescription: false,
    }

    return (
        <Screen>
            {pageDetails}
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                {!trustedAppsLtiEnabled && (
                    <TrustedAppsLockedConnectionWidget height={WIDGET_HEIGHT} />
                )}
                {trustedAppsLtiEnabled && (
                    <TrustedAppsConnectionList
                        configs={ltiConfigurations}
                        updateList={fetchConfigs}
                    />
                )}
                <TrustedAppsAdditionalInfoWidget
                    height={WIDGET_HEIGHT}
                    details={trustedAppsDetails}
                />
            </div>
        </Screen>
    )
}
