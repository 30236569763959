export const CONTRIBUTING_ORG_MEMBERSHIP = 'CONTRIBUTING_MEMBER'
export const AFFILIATE_ORG_MEMBERSHIP = 'AFFILIATE_MEMBER'
export const ALLIANCE_ORG_MEMBERSHIP = 'ALLIANCE_MEMBER'
export const NON_MEMBER_ORG_MEMBERSHIP = 'NON_MEMBER'
export const FORMER_MEMBER_ORG_MEMBERSHIP = 'FORMER_MEMBER'

export type OrgMembershipTypes =
  | 'CONTRIBUTING_MEMBER'
  | 'AFFILIATE_MEMBER'
  | 'ALLIANCE_MEMBER'
  | 'NON_MEMBER'
  | 'FORMER_MEMBER'

export const CONTRIBUTING_ORG_MEMBERSHIP_DISPLAY = 'Contributing Member'
export const AFFILIATE_ORG_MEMBERSHIP_DISPLAY = 'Affiliate Member'
export const ALLIANCE_ORG_MEMBERSHIP_DISPLAY = 'Alliance Member'
export const NON_MEMBER_ORG_MEMBERSHIP_DISPLAY = 'Non-Member'
export const FORMER_MEMBER_ORG_MEMBERSHIP_DISPLAY = 'Former Member'

export type OrgMembershipTypesDisplay =
  | 'Contributing Member'
  | 'Affiliate Member'
  | 'Alliance Member'
  | 'Non-Member'
  | 'Former Member'

export const MEMBERSHIP_LEVEL_TO_COLOR_MAP = {
  [CONTRIBUTING_ORG_MEMBERSHIP_DISPLAY]: 'start',
  [AFFILIATE_ORG_MEMBERSHIP_DISPLAY]: 'warningAlt',
  [ALLIANCE_ORG_MEMBERSHIP_DISPLAY]: 'warning',
  [NON_MEMBER_ORG_MEMBERSHIP_DISPLAY]: 'error',
  [FORMER_MEMBER_ORG_MEMBERSHIP_DISPLAY]: 'error',
}

export const MEMBERSHIP_LEVEL_TO_BG_COLOR_MAP = {
  [CONTRIBUTING_ORG_MEMBERSHIP_DISPLAY]: 'bg-start',
  [AFFILIATE_ORG_MEMBERSHIP_DISPLAY]: 'bg-warningAlt',
  [ALLIANCE_ORG_MEMBERSHIP_DISPLAY]: 'bg-warning',
  [NON_MEMBER_ORG_MEMBERSHIP_DISPLAY]: 'bg-error',
  [FORMER_MEMBER_ORG_MEMBERSHIP_DISPLAY]: 'bg-error',
}
