import React from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { utils, records } from '@ims/1edtech-frontend-common'

import IOrg, {
  OrgCertificationLevels,
  OrgSegments,
  OrgCertificationLevelToDisplay,
} from 'domains/orgs/models/IOrg'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import {
  ModalWrapper,
  View,
  InputLabel,
  Icon,
  Text,
  FormInput,
  Select,
  DatePicker,
} from 'lib/components'
import DistrictLogo from 'domains/orgs/components/DistrictLogo'
import {
  SUPPLIER_ORG_TYPE,
  DISTRICT_ORG_TYPE,
  ORG_TYPES,
} from 'domains/authentication/constants/organizations'
import { showToast, ERROR_TOAST } from 'lib/utils/toast'
import { IFormInputProps } from 'lib/components/FormInput'
import { createRecord } from 'lib/records/workflows/createRecord'
import {
  OrgMembershipTypesDisplay,
  AFFILIATE_ORG_MEMBERSHIP_DISPLAY,
  CONTRIBUTING_ORG_MEMBERSHIP_DISPLAY,
  ALLIANCE_ORG_MEMBERSHIP_DISPLAY,
  NON_MEMBER_ORG_MEMBERSHIP_DISPLAY,
  FORMER_MEMBER_ORG_MEMBERSHIP_DISPLAY,
} from 'domains/orgs/constants/organization'
import { getRecords } from 'lib/records/workflows/getRecords'
import { SUPPLIERS_RECORD } from 'lib/records/modules/suppliers'
import SearchDropdownMenu from 'lib/components/SearchDropdownMenu'
import OrgSearchMenuItem from 'domains/orgs/components/OrgSearchMenuItem'
import { updateOrg } from 'domains/orgs/workflows/updateOrg'
import { IApiResponse } from 'lib/api/models'
import { NCESID_ALREADY_EXISTS_ERROR_MESSAGE } from 'domains/orgs/constants/errors'

function Divider() {
  return <View width="100%" height={40} />
}

interface IProps {
  isCreate?: boolean
  id?: string | number
  isOpen: boolean
  closeModal: (org?: IOrg) => any
}

interface IFormValues {
  logourl: string
  name: string
  type: ORG_TYPES
  externalurl?: string
  imsurl?: string
  membershipLevel: OrgMembershipTypesDisplay
  certificationLevel: OrgCertificationLevels
  isDistrict: boolean
  segment?: OrgSegments
  lockoutDate?: Date
  referrerId?: number
  referrerName?: string
  ncesId?: string
  country?: string
  state?: string
  trustedAppsLtiEnabled: boolean
}

export default function CreateEditOrganizationModal(props: IProps) {
  const organization = useSelector((state: any) =>
    records.entitiesSelectors.entityByIdSelector(ORGANIZATIONS_RECORD, 'id')(
      state,
      props,
    ),
  ) as IOrg

  React.useEffect(() => {
    const fetch = async () => {
      await getRecords({
        record: SUPPLIERS_RECORD,
        force: true,
        params: { sort: 'name:ascending' },
      })
    }
    fetch()
  }, [])

  const onSave = async (
    values: IFormValues,
    bag: FormikHelpers<IFormValues>,
  ) => {
    const { isCreate } = props
    values.country = values.country?.toUpperCase()

    const { isDistrict, ...fmt } = values

    const org = {
      ...fmt,
      lockoutDate: values.lockoutDate
        ? utils.date
          .getMoment(values.lockoutDate)
          .startOf('day')
          .tz('America/New_York')
          .valueOf()
        : null,
      preferredPartner: false,
    }

    let response: IApiResponse
    if (isCreate) {
      response = await createRecord(ORGANIZATIONS_RECORD, org)
    } else {
      response = await updateOrg(utils.convertToInt(props.id!), org)
    }
    if (!response.success) {
      const imsxDescription = response.data?.errors?.[0]?.imsxDescription

      if (imsxDescription === NCESID_ALREADY_EXISTS_ERROR_MESSAGE) {
        showToast(
          ERROR_TOAST,
          `Failed to ${isCreate ? 'create' : 'update'
          } organization due to a duplicate NCESID entry.`,
        )
      } else {
        showToast(
          ERROR_TOAST,
          `Failed to ${isCreate ? 'create' : 'update'} organization`,
        )
      }

      bag.setSubmitting(false)
    } else {
      bag.resetForm()
      props.closeModal(response.data)
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    memberShipLevel: Yup.mixed<OrgMembershipTypesDisplay>().oneOf([
      NON_MEMBER_ORG_MEMBERSHIP_DISPLAY,
      AFFILIATE_ORG_MEMBERSHIP_DISPLAY,
      ALLIANCE_ORG_MEMBERSHIP_DISPLAY,
      CONTRIBUTING_ORG_MEMBERSHIP_DISPLAY,
      FORMER_MEMBER_ORG_MEMBERSHIP_DISPLAY,
    ]),
    isDistrict: Yup.boolean(),
    segment: Yup.mixed<OrgSegments>().when('isDistrict', { is: true, then: Yup.mixed<OrgSegments>().oneOf(['K12', 'HED']).required('Required') }),
    ncesId: Yup.string().when('isDistrict', { is: true, then: Yup.string().required('Required') }),
    country: Yup.string().when('isDistrict', { is: true, then: Yup.string().required('Required') }),
  })

  const getInitialFormValues = (): IFormValues => {
    if (props.isCreate) {
      return {
        logourl: '',
        name: '',
        externalurl: '',
        imsurl: '',
        type: DISTRICT_ORG_TYPE,
        membershipLevel: NON_MEMBER_ORG_MEMBERSHIP_DISPLAY,
        certificationLevel: 'UNCERTIFIED',
        isDistrict: true,
        ncesId: '',
        country: '',
        state: '',
        trustedAppsLtiEnabled: false,
      }
    }

    return {
      ...organization!,
      lockoutDate: organization!.lockoutDate
        ? utils.date.getMoment(organization.lockoutDate).toDate()
        : undefined,
      isDistrict: organization!.type === DISTRICT_ORG_TYPE,
    }
  }

  const renderLinkInput = (
    url: string,
    options: IFormInputProps,
    preTest?: (url: string) => string,
  ) => {
    const testUrl = preTest ? preTest(url) : url
    const isValid = utils.url.isValidUrl(testUrl)
    return (
      <View width="100%" flexible="row-v-center">
        <View flex={1}>
          <FormInput {...options} />
        </View>
        <View flexible="column-center" alignItems="flex-end" width={100}>
          {isValid && (
            <a
              href={testUrl}
              target="_blank"
              rel="noopener noreferrer"
              data-test={`${options.valueDataTest}-link`}
            >
              <Icon
                fontSize={18}
                color="primary"
                className="fas fa-link"
                cursor="pointer"
                mx={2}
              />
              Visit Link
            </a>
          )}
          {utils.hasValue(testUrl) && !isValid && (
            <Text
              color="error"
              variant="caption"
              textAlign="center"
              ml={2}
              data-test={`${options.valueDataTest}-error`}
            >
              Invalid URL
            </Text>
          )}
        </View>
      </View>
    )
  }

  return (
    <Formik
      initialValues={getInitialFormValues()}
      onSubmit={onSave}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(bag) => {
        const isDistrict = bag.values.type === DISTRICT_ORG_TYPE
        const onChangeDate = (d: any) => bag.setFieldValue('lockoutDate', d)
        const onReferringSupplierSelected = (supplier: IOrg) => {
          bag.setFieldValue('referrerId', supplier.id)
          bag.setFieldValue('referrerName', supplier.name)
        }
        const onReferringSupplierCleared = () => {
          bag.setFieldValue('referrerId', null)
          bag.setFieldValue('referrerName', null)
        }

        return (
          <ModalWrapper
            isOpen={props.isOpen}
            title={
              props.isCreate ? 'New District' : 'Edit Organization Details'
            }
            actions={[
              {
                text: 'Save',
                variant: 'start',
                onClick: bag.submitForm,
                extra: {
                  type: 'submit',
                },
              },
              {
                text: 'Cancel',
                variant: 'neutral',
                onClick: () => {
                  bag.resetForm()
                  props.closeModal()
                },
                extra: {
                  type: 'button',
                },
              },
            ]}
            pending={bag.isSubmitting}
          >
            <View minWidth={['none', 600]}>
              <FormInput
                label="Name"
                name="name"
                value={bag.values.name}
                valueDataTest="name-input"
                placeholder="Organization Name"
                handleChange={bag.handleChange}
                required
              />
              <Divider />

              <View
                mb={3}
                display="grid"
                gridTemplateColumns="1fr 1fr"
                gridColumnGap={3}
              >
                <View>
                  <InputLabel>Membership Level <span className="ml-2 text-error">*</span></InputLabel>
                  <Select
                    name="membershipLevel"
                    variant="bold"
                    value={bag.values.membershipLevel}
                    onChange={bag.handleChange}
                    width="100%"
                    mt={3}
                    data-test="org-membershipLevel-selector"
                  >
                    <option value={NON_MEMBER_ORG_MEMBERSHIP_DISPLAY}>
                      {NON_MEMBER_ORG_MEMBERSHIP_DISPLAY}
                    </option>
                    <option value={AFFILIATE_ORG_MEMBERSHIP_DISPLAY}>
                      {AFFILIATE_ORG_MEMBERSHIP_DISPLAY}
                    </option>
                    <option value={ALLIANCE_ORG_MEMBERSHIP_DISPLAY}>
                      {ALLIANCE_ORG_MEMBERSHIP_DISPLAY}
                    </option>
                    <option value={CONTRIBUTING_ORG_MEMBERSHIP_DISPLAY}>
                      {CONTRIBUTING_ORG_MEMBERSHIP_DISPLAY}
                    </option>
                    <option value={FORMER_MEMBER_ORG_MEMBERSHIP_DISPLAY}>
                      {FORMER_MEMBER_ORG_MEMBERSHIP_DISPLAY}
                    </option>
                  </Select>
                </View>

                <View>
                  <InputLabel>Certification Level</InputLabel>
                  <Select
                    name="certificationLevel"
                    variant="bold"
                    value={bag.values.certificationLevel}
                    onChange={bag.handleChange}
                    width="100%"
                    mt={3}
                    data-test="org-certificationLevel-selector"
                  >
                    <option value="UNCERTIFIED">
                      {OrgCertificationLevelToDisplay.UNCERTIFIED}
                    </option>
                    <option value="LEVEL_1">
                      {OrgCertificationLevelToDisplay.LEVEL_1}
                    </option>
                    <option value="LEVEL_2">
                      {OrgCertificationLevelToDisplay.LEVEL_2}
                    </option>
                    <option value="LEVEL_3">
                      {OrgCertificationLevelToDisplay.LEVEL_3}
                    </option>
                  </Select>
                </View>

                {isDistrict && (
                  <View mt={3}>
                    <InputLabel>Segment<span className="ml-2 text-error">*</span></InputLabel>
                    <Select
                      name="segment"
                      variant="bold"
                      placeholder="Segment"
                      defaultValue=""
                      value={bag.values.segment}
                      onChange={bag.handleChange}
                      width="100%"
                      mt={3}
                      data-test="org-segment-selector"
                      required
                    >
                      <option value="" disabled>Select a segment</option>
                      <option value="K12">K12</option>
                      <option value="HED">HED</option>
                    </Select>
                  </View>
                )}
              </View>

              {isDistrict && (
                <>
                  <View
                    width="100%"
                    display="grid"
                    gridTemplateColumns={'1fr 1fr'}
                    gridColumnGap={3}
                    mb={3}
                  >
                    <View width="100%">
                      <InputLabel>Lockout Date</InputLabel>
                      <DatePicker
                        date={bag.values.lockoutDate!}
                        handleChange={onChangeDate}
                        isClearable={true}
                        openToDate={utils.date
                          .getMoment()
                          .add(6, 'months')
                          .toDate()}
                        data-test="lockout-date-input"
                      />
                    </View>

                    <View>
                      <InputLabel>Referring Supplier</InputLabel>
                      <SearchDropdownMenu
                        record={SUPPLIERS_RECORD}
                        MenuItemComponent={OrgSearchMenuItem}
                        onItemSelected={onReferringSupplierSelected}
                        onCleared={onReferringSupplierCleared}
                        recordParams={{
                          sort: 'name:ascending',
                          filter: 'name=~@@',
                        }}
                        selectedName={bag.values.referrerName || ''}
                        noHoverRemove={true}
                        fontSize={14}
                        placeholder="Supplier Name"
                        data-test="referrer-search"
                      />
                    </View>
                  </View>
                  <Divider />
                </>
              )}

              {isDistrict && (
                <>
                  <View
                    width="100%"
                    display="grid"
                    gridTemplateColumns={'2fr 1fr 1fr'}
                    gridColumnGap={3}
                  >
                    <FormInput
                      label="NCESID"
                      name="ncesId"
                      value={bag.values.ncesId || ''}
                      valueDataTest="ncesId-input"
                      placeholder="abc123"
                      handleChange={bag.handleChange}
                      required
                    />

                    <FormInput
                      label="Country"
                      name="country"
                      value={bag.values.country?.toUpperCase() || ''}
                      valueDataTest="country-input"
                      placeholder="US"
                      handleChange={bag.handleChange}
                      required
                    />

                    <FormInput
                      label="State"
                      name="state"
                      value={bag.values.state || ''}
                      valueDataTest="state-input"
                      placeholder="CO"
                      handleChange={bag.handleChange}
                    />
                  </View>
                  <Divider />
                </>
              )}

              <View width="100%" flexible="row-v-center">
                <View flex={3}>
                  <FormInput
                    label="Logo"
                    name="logourl"
                    value={bag.values.logourl}
                    valueDataTest="logo-input"
                    placeholder="https://somelogosite.net/logos/something.png"
                    handleChange={bag.handleChange}
                  />
                </View>
                <View flex={1} flexible="column-center" alignItems="flex-end">
                  <DistrictLogo
                    logoUrl={bag.values.logourl}
                    name={bag.values.name}
                    size={80}
                    fallbackIcon={
                      bag.values.type === SUPPLIER_ORG_TYPE
                        ? 'fas fa-building'
                        : 'fas fa-school'
                    }
                    displayError="Invalid Logo URL"
                    data-test="org-logo"
                  />
                </View>
              </View>
              {renderLinkInput(
                bag.values.imsurl || '',
                {
                  label: '1EdTech Page',
                  name: 'imsurl',
                  value: bag.values.imsurl || '',
                  valueDataTest: 'imsurl-input',
                  placeholder: '/content/org-name',
                  handleChange: bag.handleChange,
                },
                utils.url.getIMSUrl,
              )}
              {renderLinkInput(bag.values.externalurl || '', {
                label: 'Website',
                name: 'externalurl',
                value: bag.values.externalurl || '',
                valueDataTest: 'externalurl-input',
                placeholder: 'https://orgwebsite.biz',
                handleChange: bag.handleChange,
              })}
            </View>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
