import useRecordEntity from 'lib/records/hooks/useRecordEntity'
import { TRUSTED_APPS_TOOLUSES_DATA_RECORD } from 'lib/records/modules/trustedAppsTooluses'

type ToolusesData = {
  tools: string[]
}

export default function useTrustedAppsTooluses() {
  const [data, pending] = useRecordEntity({
    record: TRUSTED_APPS_TOOLUSES_DATA_RECORD,
    id: 1,
    noReFetch: true,
  }) as [ToolusesData | null, boolean]

  return [data?.tools, pending] as const
}
