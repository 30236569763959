import IIMSApplication from 'domains/trustedApps/models/IIMSApplication'
import IIMSApplicationVetting from 'domains/trustedApps/models/IIMSApplicationVetting'

export const TRUSTED_APPS_DENIED_STATUS = 'DENIED' // 0
export const TRUSTED_APPS_APPROVED_STATUS = 'APPROVED' // 1
export const TRUSTED_APPS_PREFERRED_STATUS = 'PREFERRED' // 2
export const TRUSTED_APPS_APPROVED_LIMITED_STATUS = 'APPROVED_LIMITED' // 3
export const TRUSTED_APPS_UNDER_REVIEW_STATUS = 'UNDER_REVIEW' // 4
export const TRUSTED_APPS_REVIEWED_STATUS = 'REVIEWED' // 5
export const TRUSTED_APPS_UNRATED_STATUS = 'UNRATED' //6

export const TRUSTED_APPS_PRIOTIRY_MAP = {
  [TRUSTED_APPS_DENIED_STATUS]: 0,
  [TRUSTED_APPS_PREFERRED_STATUS]: 1,
  [TRUSTED_APPS_APPROVED_STATUS]: 2,
  [TRUSTED_APPS_APPROVED_LIMITED_STATUS]: 3,
  [TRUSTED_APPS_UNDER_REVIEW_STATUS]: 4,
  [TRUSTED_APPS_REVIEWED_STATUS]: 5,
  [TRUSTED_APPS_UNRATED_STATUS]: 6,
}

export const TRUSTED_APPS_STATUS_DISPLAY_MAP = {
  DENIED: 'Denied',
  APPROVED: 'Approved',
  PREFERRED: 'Preferred',
  APPROVED_LIMITED: 'Approved Limited',
  UNDER_REVIEW: 'Under Review',
  REVIEWED: 'Reviewed',
  UNRATED: 'Unrated',
}

export type TrustedApplicationStatusesType =
  | 'DENIED'
  | 'APPROVED'
  | 'PREFERRED'
  | 'APPROVED_LIMITED'
  | 'UNDER_REVIEW'
  | 'REVIEWED'
  | 'UNRATED'

export const TRUSTED_APPS_NOTES_DISPLAY_MAP = {
  technicalNotes: 'Technical Notes',
  instructionalNotes: 'Instructional Notes',
}

export enum TrustedAppStatuses {
  DENIED = 'DENIED',
  APPROVED = 'APPROVED',
  PREFERRED = 'PREFERRED',
  APPROVED_LIMITED = 'APPROVED_LIMITED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  REVIEWED = 'REVIEWED',
  UNRATED = 'UNRATED',
}

export type TRUSTED_APP_MAP_TO_STRING = {
  DENIED: string
  APPROVED: string
  PREFERRED: string
  APPROVED_LIMITED: string
  UNDER_REVIEW: string
  REVIEWED: string
  UNRATED: string
}
export const TRUSTED_APP_STATUS_DISPLAY: TRUSTED_APP_MAP_TO_STRING = {
  DENIED: 'DENIED',
  APPROVED: 'APPROVED',
  PREFERRED: 'PREFERRED',
  APPROVED_LIMITED: 'APPROVED LIMITED',
  UNDER_REVIEW: 'UNDER REVIEW',
  REVIEWED: 'REVIEWED',
  UNRATED: 'UNRATED',
}
export const TRUSTED_APP_STATUS_ICON_MAP: TRUSTED_APP_MAP_TO_STRING = {
  DENIED: 'fas fa-times',
  APPROVED: 'fas fa-check-circle',
  PREFERRED: 'fas fa-star',
  APPROVED_LIMITED: 'fas fa-check-square',
  UNDER_REVIEW: 'fas fa-exclamation-triangle',
  REVIEWED: 'fas fa-clipboard-check',
  UNRATED: 'fas fa-minus',
}

export const TRUSTED_APP_STATUS_COLOR_MAP: TRUSTED_APP_MAP_TO_STRING = {
  DENIED: 'error',
  APPROVED: 'success',
  PREFERRED: 'gold',
  APPROVED_LIMITED: 'ta-approved-limited-purple',
  UNDER_REVIEW: 'errorAlt',
  REVIEWED: 'ta-blue-4',
  UNRATED: 'ta-gray-2',
}

export default interface ITrustedApplication {
  id: number
  applicationId: number // the same as id
  status: TrustedApplicationStatusesType
  logo: string
  title: string
  orgName: string
  certified: boolean
  vetted: boolean
  sealed?: boolean
  technicalNotes: string
  instructionalNotes: string
  coreOrSupplemental?: string
  subjects?: string[]
  tooluses?: string[]
  grades?: string[]
  reviwedByInstructionalDesigners?: boolean
  rosteringMethod?: string
  gradePassbackMethod?: string
  accessMethods?: string[]
  securityReview?: string
  learningStandardsAlignment?: string
  downstreamAnalytics?: string
  dataSharing?: string
  licensingCoverage: string[]
  licensingExpirationDate?: number
  licensingFees?: string
}

export interface IFullTrustedApplication extends ITrustedApplication {
  application: IIMSApplication
}

export interface ICustomTrustedApplication
  extends Omit<ITrustedApplication, 'id' | 'logo'> {
  vetting?: IIMSApplicationVetting
  applicationUrl?: string
  description?: string
}

export const hasAdditionalInfo = (app?: ITrustedApplication): boolean => {
  const values = [
    app?.technicalNotes,
    app?.instructionalNotes,
    app?.coreOrSupplemental,
    app?.subjects,
    app?.tooluses,
    app?.grades,
    app?.reviwedByInstructionalDesigners,
    app?.rosteringMethod,
    app?.gradePassbackMethod,
    app?.accessMethods,
    app?.securityReview,
    app?.learningStandardsAlignment,
    app?.licensingCoverage,
    app?.licensingFees,
  ]
  const nonNullValues = values.filter((val) => {
    if (Array.isArray(val)) {
      return val.length > 0
    }

    return ![undefined, null].includes(val as any)
  })

  return nonNullValues.length > 0
}
