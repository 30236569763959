import { TrustedApplicationStatusesType } from 'domains/trustedApps/models/ITrustedApplication'

export interface IAddTrustedAppSelection {
  applicationId: number
  status: TrustedApplicationStatusesType
  technicalNotes: string
  instructionalNotes: string
  coreOrSupplemental?: string
  subjects?: string[]
  tooluses?: string[]
  grades?: string[]
  reviwedByInstructionalDesigners?: boolean
  rosteringMethod?: string
  gradePassbackMethod?: string
  accessMethods?: string[]
  securityReview?: string
  learningStandardsAlignment?: string
  downstreamAnalytics?: string
  dataSharing?: string
  licensingCoverage: string[]
  licensingExpirationDate?: number
  licensingFees?: string
}

export const hasAdditionalInfo = (app?: IAddTrustedAppSelection): boolean => {
  const values = [
    app?.technicalNotes,
    app?.instructionalNotes,
    app?.coreOrSupplemental,
    app?.subjects,
    app?.tooluses,
    app?.grades,
    app?.reviwedByInstructionalDesigners,
    app?.rosteringMethod,
    app?.gradePassbackMethod,
    app?.accessMethods,
    app?.securityReview,
    app?.learningStandardsAlignment,
    app?.downstreamAnalytics,
    app?.dataSharing,
    app?.licensingCoverage,
    app?.licensingExpirationDate,
    app?.licensingFees
  ]
  const nonNullValues = values.filter(
    (val) =>
      ![undefined, null, ""].includes(
        val as any,
      ),
  ).filter((val) => Array.isArray(val) ? val.length : true)
  return nonNullValues.length > 0
}
