import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import {
  List,
  ListCallToActionButton,
  ListPlainTextCell,
  Text,
} from 'lib/components'
import { TRUSTED_APPS_RECORD } from 'lib/records/modules/trustedApplications'
import { RootState } from 'lib/store/rootReducer'
import trackTrustedAppsAnalytics from 'domains/trustedApps/utils/trackTrustedAppsAnalytics'
import { IListColumnDefinition } from 'lib/components/List'
import TrustedApplicationNameCell from 'domains/trustedApps/components/TrustedAppsList/TrustedApplicationNameCell'
import ITrustedApplication, {
  TrustedApplicationStatusesType,
} from 'domains/trustedApps/models/ITrustedApplication'
import TrustedApplicationDeleteCell from 'domains/trustedApps/components/TrustedAppsList/TrustedApplicationDeleteCell'
import Dialog from 'domains/application/modals/Dialog'
import useModalState from 'lib/hooks/useModalState'
import { deleteTrustedApp } from 'domains/trustedApps/workflows/deleteTrustedApp'
import { showToast } from 'lib/utils/toast'
import { useHistory } from 'react-router-dom'
import {
  DISTRICT_TRUSTED_APPS_ADD_ROUTE,
  DISTRICT_TRUSTED_APPS_ADD_CUSTOM_ROUTE,
} from 'domains/application/navigation/routes'
import TrustedAppsStatusSelector from 'domains/trustedApps/components/AddTrustedApps/TrustedAppsStatusSelector'
import { updateTrustedApp } from 'domains/trustedApps/workflows/updateTrustedApp'
import TrustEdAppsSeal from 'domains/trustedApps/components/TrustEdAppsSeal'
import TrustedAppsAdditionalInfoCell from 'domains/trustedApps/components/TrustedAppsList/TrustedAppsAdditionalInfoCell'
import EditAdditionalInfoModal from 'domains/trustedApps/components/EditAdditionalInfoModal'
import TrustedAppsAdvancedSearchModal, {
  getTrustedAppAdvancedSearchFilterCount,
  TrustedAppsAdvancedSearchFilter,
} from 'domains/trustedApps/components/TrustedAppsAdvancedSearchModal'
import useRecordEntity from 'lib/records/hooks/useRecordEntity'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import IOrg from 'domains/orgs/models/IOrg'
import { useReportDownloadFetcher } from 'domains/reports/components/DownloadReport'
import { goToTeacherDashboard } from 'domains/trustedApps/workflows/goToTeacherDashboard'
import { amIHed } from 'lib/constants/vocab'
import { useTrustedAppsFilter } from 'domains/trustedApps/hooks/useTrustedAppsFilter'

const SEARCH_TEMPLATE = `title=~@@ OR orgName=~@@ OR technicalNotes=~@@ OR instructionalNotes=~@@`

export default function TrustedAppsList(props: { parentOrgId?: number }) {
  const SITE_URL = utils.env.getEnvVariable('SITE_URL', process.env.SITE_URL)
  const history = useHistory()
  const trustedApps = useSelector((s: RootState) =>
    records.recordsSelectors.fullRecordsSelector(TRUSTED_APPS_RECORD)(s, {}),
  )
  const [orgBeingViewed] = useRecordEntity({
    record: ORGANIZATIONS_RECORD,
    id: props.parentOrgId,
  }) as [IOrg | null, boolean]

  const url = amIHed()
    ? 'trustedapps/organizations/applications/csv/download?orgType=HED'
    : 'trustedapps/organizations/applications/csv/download'
  const downloadReport = useReportDownloadFetcher({
    url: url,
    filename: 'trustEd-apps.csv',
  })
  const [downloadPending, setDownloadPending] = React.useState(false)
  const onDownloadReport = async () => {
    setDownloadPending(true)
    await downloadReport()
    setDownloadPending(false)
  }

  const sharedTrustedApps = useSelector((s: RootState) =>
    records.relatedRecordsSelectors.fullRelatedRecordsSelector(
      TRUSTED_APPS_RECORD,
      'id',
    )(s, { id: props.parentOrgId }),
  )

  const [additionalInfoOpen, openAdditionalInfo, _closeAdditionalInfo] =
    useModalState()
  const [resetListToggle, setResetListToggle] = React.useState(false)
  const closeAdditionalInfo = (saved?: boolean) => {
    _closeAdditionalInfo()
    if (saved === true && activeFilterCount)
      setResetListToggle(!resetListToggle)
  }
  const [additionalInfoApp, setAdditionalInfoApp] = React.useState<
    ITrustedApplication | undefined
  >()

  const onOpenAdditionalInfo = (app: ITrustedApplication) => {
    setAdditionalInfoApp(app)
    openAdditionalInfo()
  }

  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModalState()
  const [appToBeDeleted, setAppToBeDeleted] =
    React.useState<ITrustedApplication | null>(null)
  const onDeleteApp = (app: ITrustedApplication) => {
    setAppToBeDeleted(app)
    openDeleteModal()
  }

  const onDeleteAppConfirmed = async () => {
    const app = appToBeDeleted as ITrustedApplication
    const success = await deleteTrustedApp(app.id)

    if (success) {
      showToast(
        'start',
        `Successfully deleted ${app.title} from your organization's TrustEd Apps`,
      )
      closeDeleteModal()
      setAppToBeDeleted(null)
    } else {
      showToast('error', 'Failed to delete TrustEd App')
    }
  }

  const [
    advancedSearchModalOpen,
    openAdvancedSearchModal,
    closeAdvancedSearchModal,
  ] = useModalState()
  const [searchQuery, setSearchQuery] = React.useState('')
  const [trustedAppsFilter, setTrustedAppsFilter] = useTrustedAppsFilter()

  const onAdvanceSearchSubmit = (
    query: string,
    filter: TrustedAppsAdvancedSearchFilter,
  ) => {
    setSearchQuery(query)
    setTrustedAppsFilter(filter)
  }

  const activeFilterCount =
    getTrustedAppAdvancedSearchFilterCount(trustedAppsFilter)

  let columns: IListColumnDefinition[] = [
    {
      title: 'Application',
      CellComponent: TrustedApplicationNameCell,
      sortKey: utils.sort.addSortValue('title@'),
      noCenter: true,
    },
    {
      title: 'TrustEd Apps Seal',
      CellComponent: (props: ITrustedApplication) => {
        if (props.sealed) {
          return <TrustEdAppsSeal />
        }

        return <ListPlainTextCell>No</ListPlainTextCell>
      },
      sortKey: utils.sort.addSortValue('sealed@,title:ascending', true),
    },
    {
      title: 'Vetted',
      CellComponent: (props: ITrustedApplication) => (
        <ListPlainTextCell>{props.vetted ? 'Yes' : 'No'}</ListPlainTextCell>
      ),
      sortKey: utils.sort.addSortValue('vetted@,title:ascending'),
    },
    {
      title: 'Certified',
      CellComponent: (props: ITrustedApplication) => (
        <ListPlainTextCell>{props.certified ? 'Yes' : 'No'}</ListPlainTextCell>
      ),
      sortKey: utils.sort.addSortValue('certified@,title:ascending'),
    },
    {
      title: 'Supplier',
      accessor: 'orgName',
      sortKey: utils.sort.addSortValue('orgName@,title:ascending'),
      noHeaderCenter: true,
      noCenter: true,
    },
  ]

  if (!props.parentOrgId) {
    columns = [
      ...columns,
      {
        title: 'Additional Info',
        CellComponent: TrustedAppsAdditionalInfoCell,
        cellProps: { onClick: onOpenAdditionalInfo },
        noSort: true,
      },
      {
        title: 'Delete',
        CellComponent: TrustedApplicationDeleteCell,
        cellProps: { onClick: onDeleteApp },
        noSort: true,
      },
    ]
  }

  columns.splice(1, 0, {
    title: 'Rating',
    CellComponent: (props: ITrustedApplication) => {
      const onStatusChange = async (
        id: number,
        status: TrustedApplicationStatusesType,
      ) => {
        await updateTrustedApp(id, { ...props, status })
        if (trustedAppsFilter.status.length)
          setResetListToggle(!resetListToggle)
      }
      return (
        <TrustedAppsStatusSelector
          {...props}
          applicationId={props.id}
          status={props.status}
          onStatusChange={onStatusChange}
        />
      )
    },
    sortKey: utils.sort.addSortValue('status@,title:ascending'),
  })

  columns = columns.map((c) => ({
    ...c,
    cellProps: {
      ...R.pathOr({}, ['cellProps'], c),
      parentOrgId: props.parentOrgId,
    },
  }))

  const onAddNew = () => history.push(DISTRICT_TRUSTED_APPS_ADD_ROUTE)
  const onAddCustom = () => history.push(DISTRICT_TRUSTED_APPS_ADD_CUSTOM_ROUTE)

  const goToTrustEdAppsDashboard = () => goToTeacherDashboard(true)

  const baseFilterParam = Object.keys(trustedAppsFilter).reduce((agg, key) => {
    const filter =
      trustedAppsFilter[key as keyof TrustedAppsAdvancedSearchFilter]
    const isArray = Array.isArray(filter)
    if ((isArray && filter.length) || (!isArray && !!filter)) {
      const existing = `${agg}${agg.length ? ' AND ' : ''}`
      if (isArray)
        return `${existing}${filter.map((v) => `${key}=${v}`).join(' OR ')}`
      return `${existing}${key}=${filter}`
    }

    return agg
  }, '')

  return (
    <>
      <List
        title={`${
          orgBeingViewed ? `${orgBeingViewed.name} - ` : ''
        }TrustEd Apps`}
        record={TRUSTED_APPS_RECORD}
        recordState={props.parentOrgId ? sharedTrustedApps : trustedApps}
        columns={columns}
        parentRecordId={props.parentOrgId}
        rowHeight={68}
        dataTest="trusted-apps-list"
        trackAnalytics={trackTrustedAppsAnalytics}
        trackAnalyticsCategory="trusted_apps_list"
        initialSortColumnIndex={1}
        query={searchQuery}
        onQueryChange={setSearchQuery}
        baseFilterParam={baseFilterParam.length ? baseFilterParam : undefined}
        searchTemplate={SEARCH_TEMPLATE}
        resetListToggle={resetListToggle}
        callToAction={
          <div className="flex items-center flex-wrap gap-x-2 gap-y-2">
            <ListCallToActionButton
              text={`Filter${
                activeFilterCount > 0 ? ` (${activeFilterCount})` : ''
              }`}
              onClick={openAdvancedSearchModal}
              icon="fas fa-filter"
              data-test="advanced-search-btn"
              variant="complete"
            />
            {!props.parentOrgId && (
              <>
                <ListCallToActionButton
                  text="Export"
                  onClick={onDownloadReport}
                  icon="fas fa-download"
                  data-test="export-btn"
                  variant="complete"
                  pending={downloadPending}
                />
                <ListCallToActionButton
                  text="Add Custom App"
                  onClick={onAddCustom}
                  data-test="add-custom-btn"
                />
                <ListCallToActionButton
                  onClick={onAddNew}
                  data-test="add-new-btn"
                />
                <ListCallToActionButton
                  text="Preview"
                  onClick={goToTrustEdAppsDashboard}
                  icon="fas fa-external-link-square-alt"
                  variant="complete"
                  data-test="preview-dashboard-btn"
                />
              </>
            )}
          </div>
        }
      />
      <Text variant="caption" color="textLight" pt="24px">
        Disclaimer:{' '}
        <a
          href={`https://www.${
            SITE_URL ? SITE_URL : 'imsglobal.org'
          }/activity/trusted-apps-data-privacy-certification`}
        >
          1EdTech TrustEd Apps
        </a>{' '}
        provides a vetting of a product's data policy according to the{' '}
        <a
          href={`https://www.${
            SITE_URL ? SITE_URL : 'imsglobal.org'
          }/activity/app-vetting/rubric-light`}
        >
          1EdTech TrustEd Apps Rubric
        </a>
        . Achieving the{' '}
        <a
          href={`https://www.${
            SITE_URL ? SITE_URL : 'imsglobal.org'
          }/activity/trustedapps/seal`}
        >
          TrustEd Apps Certified Seal
        </a>{' '}
        indicates adherence to a baseline level of privacy. Institutions should
        review an application's detailed vetting results before approving it for
        use.
      </Text>

      <Text variant="caption" color="textLight" pt="24px">
        The information and materials provided through the Site, including any
        data, text, photographs, graphics, images, audio and video clips, logos,
        icons, software, and links (collectively, the “Materials”) are intended
        to educate and inform. They belong to either 1EdTech or their
        appropriate owners.
      </Text>

      <Text variant="caption" color="textLight" pt="24px">
        For more information about{' '}
        <em>Trusted Apps Data Privacy Certification</em> and to earn the{' '}
        <em>Trusted Apps Seal of Data Privacy</em> visit our website{' '}
        <a href={'https://www.imsglobal.org/activity/trustedapps/seal'}>
          TrustEd Apps™ Seal of Data Privacy | 1EdTech
        </a>
      </Text>

      {utils.hasValue(appToBeDeleted) && deleteModalOpen && (
        <Dialog
          isOpen={deleteModalOpen}
          icon="fas fa-trash"
          message={`Are you sure you want to delete ${
            (appToBeDeleted as ITrustedApplication).title
          } from your organization's TrustEd Applications?`}
          confirmText="Yes, Delete"
          onConfirm={onDeleteAppConfirmed}
          onDeny={closeDeleteModal}
        />
      )}

      <EditAdditionalInfoModal
        isOpen={!!additionalInfoApp && additionalInfoOpen}
        onRequestClose={closeAdditionalInfo}
        app={additionalInfoApp}
      />
      <TrustedAppsAdvancedSearchModal
        isOpen={advancedSearchModalOpen}
        onRequestClose={closeAdvancedSearchModal}
        query={searchQuery}
        filter={trustedAppsFilter}
        onSearch={onAdvanceSearchSubmit}
      />
    </>
  )
}
