import React from 'react'
import clsx from 'clsx'

import { Icon, Text } from 'lib/components'
import {
  TrustedApplicationStatusesType,
  TRUSTED_APP_MAP_TO_STRING,
  TRUSTED_APP_STATUS_COLOR_MAP,
  TRUSTED_APP_STATUS_DISPLAY,
  TRUSTED_APP_STATUS_ICON_MAP,
} from 'domains/trustedApps/models/ITrustedApplication'

interface IProps {
  status: TrustedApplicationStatusesType
  'data-test'?: string
  small?: boolean
  logo?: string
}

export default function TrustedAppStatusChip(props: IProps) {
  const { logo } = props
  const icon = TRUSTED_APP_STATUS_ICON_MAP[props.status]

  return (
    <div
      className="flex flex-row items-center p-2"
      data-test={props['data-test'] || 'trusted-app-status'}
    >
      <div
        className={clsx({
          'w-6': !props.small,
          'w-4': props.small,
        })}
      >
        {props.status === 'PREFERRED' && logo ? (
          <img
            className={clsx({
              'w-6 max-h-6': !props.small,
              'w-4 max-h-4': props.small,
            })}
            alt={`${props.status} icon`}
            src={logo}
          />
        ) : icon.includes('.svg') ? (
          <img
            className={clsx({
              'w-6 h-6': !props.small,
              'w-4 h-4': props.small,
            })}
            alt={`${props.status} icon`}
            src={icon}
          />
        ) : (
          <Icon
            className={icon}
            color={TRUSTED_APP_STATUS_COLOR_MAP[props.status]}
            fontSize={props.small ? 18 : 24}
          />
        )}
      </div>

      <Text fontWeight={700} ml={2} userSelect="none">
        {
          TRUSTED_APP_STATUS_DISPLAY[
            props.status.toUpperCase() as keyof TRUSTED_APP_MAP_TO_STRING
          ]
        }
      </Text>
    </div>
  )
}
