import React from 'react'
import clsx from 'clsx'

import { Icon } from 'lib/components'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'

const isPrint = isOnPrintScreen()

interface IProps {
  name: string
  open: boolean
  'data-test': string
  first?: boolean
}

export default function CriticalErrorHeader(props: IProps) {
  if (isPrint) {
    return <div className={clsx({ 'mt-4': !props.first })} />
  }

  return (
    <div
      data-test={props['data-test']}
      className={clsx(
        'bg-error rounded flex flex-row justify-between items-center p-4',
        {
          'mt-3': !props.first,
        },
      )}
    >
      <h3 className="text-base font-bold italic text-white">{props.name}</h3>

      <div className="flex flex-row items-center">
        <h4 className="text-base font-bold italic text-white">
          critical exceptions
        </h4>
        <Icon
          ml={1}
          fontSize={18}
          color="white"
          className={`fas rotate-caret fa-arrow-circle-down ${
            props.open ? 'down' : ''
          }`}
        />
      </div>
    </div>
  )
}
