import React from 'react'
import styled from 'styled-components'
import View from 'lib/components/View'
import colors from 'lib/styles/colors'

const CheckedIcon = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
const IndeterminateIcon = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
  fill: white;
  stroke: white;
  stroke-width: 2px;
`
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

interface IStyledCheckboxProps {
  checked: boolean
  isIndeterminate?: boolean
}

const StyledCheckbox = styled.div<IStyledCheckboxProps>`
  position: relative;
  display: inline-block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  background: ${(props) =>
    props.checked || props.isIndeterminate ? colors.complete : colors.white};
  border: ${(props) =>
    props.checked || props.isIndeterminate
      ? 'solid 1px transparent'
      : `solid 1px ${colors.neutral}`};
  border-radius: 3px;
  transition: all 150ms;
  margin-right: 8px;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${colors.primary};
  }

  ${CheckedIcon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
  ${IndeterminateIcon} {
    visibility: ${(props) => (props.isIndeterminate ? 'visible' : 'hidden')};
  }
`

interface IEventTarget {
  value: any
}
export interface ICheckboxEvent {
  target: any
  currentTarget: IEventTarget
}
interface IProps {
  checked: boolean
  isIndeterminate?: boolean
  children?: any
  name: string
  onChange: (event: React.FormEvent<HTMLInputElement>) => any
  'data-test'?: string
  minHeight?: number | string
  minWidth?: number | string
  hidden?: boolean
  required?: boolean
}

export default function Checkbox(props: IProps) {
  const ref = React.useRef<HTMLInputElement>(null)
  const {
    checked,
    children,
    'data-test': dataTest,
    isIndeterminate = false,
    minHeight = 36,
    minWidth = 36,
    ...rest
  } = props

  const onClick = (e: any) => {
    e.preventDefault()
    if (ref.current) {
      ref.current.click()
    }
  }

  return (
    <View
      display={props.hidden ? 'none' : 'flex'}
      flexible="row-v-center"
      minHeight={minHeight}
      minWidth={minWidth}
      cursor="pointer"
      onClick={onClick}
      userSelect="none"
      data-test={dataTest}
      data-checked={
        checked ? 'true' : isIndeterminate ? 'indeterminate' : 'false'
      }
    >
      <HiddenCheckbox
        checked={checked}
        {...rest}
        ref={ref}
        onChange={props.onChange}
      />
      <StyledCheckbox checked={checked} isIndeterminate={props.isIndeterminate}>
        <CheckedIcon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </CheckedIcon>
        <IndeterminateIcon viewBox="0 0 24 24">
          <g fill="currentColor">
            <rect height="2" width="18" x="3" y="12" />
          </g>
        </IndeterminateIcon>
      </StyledCheckbox>
      <div className="flex">
          {props.required && <span className="text-error text-xl font-bold px-2">*</span>}
          {children}
      </div>
    </View>
  )
}
