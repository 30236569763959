import React from 'react'
import { toast, ToastOptions } from 'react-toastify'
import * as R from 'ramda'

import View from 'lib/components/View'
import Text from 'lib/components/Text'
import Icon from 'lib/components/Icon'

export const SUCCESS_TOAST = 'start'
export const ERROR_TOAST = 'error'
export const INFO_TOAST = 'info'
export type ToastTypes = 'start' | 'error' | 'info'

const ICON_MAP = {
  [SUCCESS_TOAST]: 'fas fa-info-circle',
  [ERROR_TOAST]: 'fas fa-exclamation-circle',
  [INFO_TOAST]: 'fas fa-info-circle',
}

interface IProps {
  closeToast?: () => any
}
interface ICustomToastOptions extends ToastOptions {
  iconMap?: any
  iconType?: string
  onClick?: () => any
}
export const showToast = (
  type: ToastTypes,
  message: string | any,
  options?: ICustomToastOptions,
) => {
  function Toast(props: IProps) {
    const closeToast = props.closeToast || (() => null)
    const onClick =
      options && options.onClick
        ? () => {
            options.onClick!()
            closeToast()
          }
        : () => {
            closeToast()
          }

    const onCloseToastFromIcon = (e: any) => {
      e.stopPropagation()
      closeToast()
    }

    const map = options && options.iconMap ? options.iconMap : ICON_MAP
    const iconType = options && options.iconType ? options.iconType : type

    return (
      <View
        bg={type}
        p={3}
        cursor={!!onClick ? 'pointer' : 'default'}
        flexible="row-v-center"
        onClick={onClick}
      >
        <Icon className={map[iconType]} fontSize={20} color="white" mr={2} />

        <View mr={16}>
          {R.is(String, message) && (
            <Text
              color="white"
              opacity={0.9}
              fontSize={13}
              fontWeight={600}
              mr={16}
            >
              {message}
            </Text>
          )}
          {!R.is(String, message) && message}
        </View>
        <Icon
          onClick={onCloseToastFromIcon}
          hover="clickable"
          cursor="pointer"
          className="fas fa-times-circle"
          fontSize={14}
          color="white"
          opacity={0.8}
          position="absolute"
          top={12}
          right={12}
        />
      </View>
    )
  }

  toast(<Toast />, {
    autoClose: 3000,
    hideProgressBar: true,
    ...(options || {}),
    closeButton: false,
    closeOnClick: !!onclick,
  })
}
