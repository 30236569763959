import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import {
  getFilterString,
  IIMSApplicationFilter,
} from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IIMSApplicationsFilter'
import IMSApplicationsCertificationsFilter from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IMSApplicationsCertificationsFilter'
import IMSApplicationsGeneralFilters from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IMSApplicationsGeneralFilters'
import { Button, ModalWrapper } from 'lib/components'
import useModalState from 'lib/hooks/useModalState'
import trackTrustedAppsAnalytics from 'domains/trustedApps/utils/trackTrustedAppsAnalytics'
import IMSApplicationsFilterGroupTitle from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IMSApplicationsFilterGroupTitle'
import Select from 'lib/components/modern/Select/Select'
import {
  solutionCategoryOptions,
  SOLUTION_CATEGORY_TO_DISPLAY_MAP,
} from 'domains/trustedApps/constants/solutionCategories'
import {
  EDUCATIONAL_AUDIENCES_TO_DISPLAY_MAP,
  educationAudienceOptions,
} from 'domains/trustedApps/constants/educationalAudiences'
import {
  resourceTypeOptions,
  RESOURCE_TYPES_TO_DISPLAY_MAP,
} from 'domains/trustedApps/constants/resourceTypes'
import useTrustedAppsSupplierOptions from 'domains/trustedApps/hooks/useTrustedAppsSupplierOptions'

type FilterTypes =
  | 'GENERAL'
  | 'CERTS'
  | 'SOLUTION_CATEGORIES'
  | 'EDUCATIONAL_AUDIENCE'
  | 'RESOURCE_TYPE'
  | 'ORGS'
type FilterOperators = ' OR ' | ' AND '

interface IProps {
  setFilter: (filter: string) => any
}

export default function IMSApplicationsFilter(props: IProps) {
  const [
    initialSupplierOptions,
    supplierOptions,
    resetSupplierOptions,
    getSupplierOptions,
  ] = useTrustedAppsSupplierOptions()
  const [filterModalOpen, openFilterModal, closeFilterModal] = useModalState()
  const [certFilters, setCertFilters] = React.useState<string[]>([])
  const [generalFilters, setGeneralFilters] = React.useState<string[]>([])
  const [selectedSolutionCategories, setSelectedSolutionCategories] =
    React.useState<string[]>([])
  const [solutionCategoryFilters, setSolutionCategoryFilters] = React.useState<
    string[]
  >([])
  const [selectedEdAuds, setSelectedEdAuds] = React.useState<string[]>([])
  const [educationalAudienceFilters, setEducationalAudienceFilters] =
    React.useState<string[]>([])
  const [selectedResTypes, setSelectedResTypes] = React.useState<string[]>([])
  const [resourceTypeFilters, setResourceTypeFilters] = React.useState<
    string[]
  >([])
  const [selectedOrgs, setSelectedOrgs] = React.useState<string[]>([])
  const [orgFilters, setOrgFilters] = React.useState<string[]>([])

  React.useEffect(() => {
    onFilterChecked('GENERAL')([])
  }, []) // eslint-disable-line

  const clearAllFilters = () => {
    setCertFilters([])
    setGeneralFilters([])
    setSolutionCategoryFilters([])
    setSelectedSolutionCategories([])
    setEducationalAudienceFilters([])
    setSelectedEdAuds([])
    setResourceTypeFilters([])
    setSelectedResTypes([])
    setOrgFilters([])
    setSelectedOrgs([])
    setOrgFilters([])
    props.setFilter('')
  }

  const getFilterOptions = (
    type: FilterTypes,
  ): [string[], (newFilters: string[]) => any, FilterOperators] => {
    switch (type) {
      case 'CERTS':
        return [certFilters, setCertFilters, ' OR ']
      case 'SOLUTION_CATEGORIES':
        return [solutionCategoryFilters, setSolutionCategoryFilters, ' OR ']
      case 'EDUCATIONAL_AUDIENCE':
        return [
          educationalAudienceFilters,
          setEducationalAudienceFilters,
          ' OR ',
        ]
      case 'RESOURCE_TYPE':
        return [resourceTypeFilters, setResourceTypeFilters, ' OR ']
      case 'ORGS':
        return [orgFilters, setOrgFilters, ' OR ']
      default:
        return [generalFilters, setGeneralFilters, ' AND ']
    }
  }

  const getOtherFilterOptions = (
    type: FilterTypes,
  ): [string[], (newFilters: string[]) => any, FilterOperators][] => {
    switch (type) {
      case 'CERTS':
        return [
          getFilterOptions('GENERAL'),
          getFilterOptions('SOLUTION_CATEGORIES'),
          getFilterOptions('EDUCATIONAL_AUDIENCE'),
          getFilterOptions('RESOURCE_TYPE'),
          getFilterOptions('ORGS'),
        ]
      case 'SOLUTION_CATEGORIES':
        return [
          getFilterOptions('CERTS'),
          getFilterOptions('GENERAL'),
          getFilterOptions('EDUCATIONAL_AUDIENCE'),
          getFilterOptions('RESOURCE_TYPE'),
          getFilterOptions('ORGS'),
        ]
      case 'EDUCATIONAL_AUDIENCE':
        return [
          getFilterOptions('CERTS'),
          getFilterOptions('GENERAL'),
          getFilterOptions('SOLUTION_CATEGORIES'),
          getFilterOptions('RESOURCE_TYPE'),
          getFilterOptions('ORGS'),
        ]
      case 'RESOURCE_TYPE':
        return [
          getFilterOptions('CERTS'),
          getFilterOptions('GENERAL'),
          getFilterOptions('SOLUTION_CATEGORIES'),
          getFilterOptions('EDUCATIONAL_AUDIENCE'),
          getFilterOptions('ORGS'),
        ]
      case 'ORGS':
        return [
          getFilterOptions('CERTS'),
          getFilterOptions('GENERAL'),
          getFilterOptions('SOLUTION_CATEGORIES'),
          getFilterOptions('EDUCATIONAL_AUDIENCE'),
          getFilterOptions('RESOURCE_TYPE'),
        ]
      default:
        return [
          getFilterOptions('CERTS'),
          getFilterOptions('SOLUTION_CATEGORIES'),
          getFilterOptions('EDUCATIONAL_AUDIENCE'),
          getFilterOptions('RESOURCE_TYPE'),
          getFilterOptions('ORGS'),
        ]
    }
  }

  const newFiltersApplied = (type: FilterTypes, newFilters: string[]) => {
    const [, setNewFilters, newFilterOperator] = getFilterOptions(type)
    setNewFilters(newFilters)
    const otherFilters = getOtherFilterOptions(type)
    const allFilters = [
      newFilters.join(newFilterOperator),
      ...otherFilters.map(([filters, , operator]) => filters.join(operator)),
    ]
      .filter(utils.hasValue)
      .join(' AND ')
    props.setFilter(allFilters)

    trackTrustedAppsAnalytics('add_apps_filtererd', {
      filter: allFilters,
    })
  }

  const getSetSelectList = (
    type: FilterTypes,
  ): ((newSelectionn: string[]) => any) => {
    switch (type) {
      case 'SOLUTION_CATEGORIES':
        return setSelectedSolutionCategories
      case 'EDUCATIONAL_AUDIENCE':
        return setSelectedEdAuds
      case 'RESOURCE_TYPE':
        return setSelectedResTypes
      case 'ORGS':
      default:
        return setSelectedOrgs
    }
  }

  const onSelectFilterChanged =
    (
      type: FilterTypes,
      filterKey: string,
      transformMap?: { [key: string]: string },
    ) =>
    (selected: string[]) => {
      const setSelected = getSetSelectList(type)
      setSelected(selected)
      newFiltersApplied(
        type,
        selected.map(
          (s) => `${filterKey}=${transformMap ? transformMap[s] : s}`,
        ),
      )
    }

  const onFilterChecked =
    (type: FilterTypes) =>
    (checked: IIMSApplicationFilter | IIMSApplicationFilter[]) => {
      const [typeFilters] = getFilterOptions(type)
      let newFilters = [...typeFilters]
      const checkedArr = utils.array.ensureArray(checked)
      const isParent = checkedArr.length > 1
      let checkedCount = 0
      checkedArr.forEach((filter: IIMSApplicationFilter) => {
        const filterString = getFilterString(filter)
        if (R.contains(filterString, newFilters)) {
          checkedCount++
        }
      })
      checkedArr.forEach((filter: IIMSApplicationFilter) => {
        const filterString = getFilterString(filter)
        if (isParent) {
          newFilters = R.without([filterString], newFilters)
          if (checkedCount < checkedArr.length) {
            newFilters = [...newFilters, filterString]
          }
        } else {
          if (R.contains(filterString, newFilters)) {
            newFilters = R.without([filterString], newFilters)
          } else {
            newFilters = [...newFilters, filterString]
          }
        }
      })
      newFiltersApplied(type, newFilters)
    }

  const filterCount =
    certFilters.length +
    generalFilters.length +
    solutionCategoryFilters.length +
    educationalAudienceFilters.length +
    resourceTypeFilters.length +
    orgFilters.length

  const clearFilters = (
    <div className="mb-2">
      <Button
        onClick={clearAllFilters}
        variant="neutral"
        data-test="clear-filters"
      >
        <div className="flex flex-row items-center">
          <i className="fas fa-times-circle mr-2" />
          Clear {filterCount} filter{filterCount > 1 ? 's' : ''}
        </div>
      </Button>
    </div>
  )

  const filterContent = (
    <div>
      <IMSApplicationsCertificationsFilter
        filters={certFilters}
        onFilterChecked={onFilterChecked('CERTS')}
      />
      <IMSApplicationsGeneralFilters
        filters={generalFilters}
        onFilterChecked={onFilterChecked('GENERAL')}
      />

      <div className="mb-3">
        <IMSApplicationsFilterGroupTitle>
          Solution Categories
        </IMSApplicationsFilterGroupTitle>
        <Select
          name="solutionCategories"
          selected={selectedSolutionCategories}
          onChange={onSelectFilterChanged(
            'SOLUTION_CATEGORIES',
            'solutionCategory',
            SOLUTION_CATEGORY_TO_DISPLAY_MAP,
          )}
          placeholder="Select one more more"
          options={solutionCategoryOptions}
          multiple
          small
        />
      </div>

      <div className="mb-3">
        <IMSApplicationsFilterGroupTitle>
          Educational Audience
        </IMSApplicationsFilterGroupTitle>
        <Select
          name="educationalAudience"
          selected={selectedEdAuds}
          onChange={onSelectFilterChanged(
            'EDUCATIONAL_AUDIENCE',
            'educationalAudience',
            EDUCATIONAL_AUDIENCES_TO_DISPLAY_MAP,
          )}
          placeholder="Select one more more"
          options={educationAudienceOptions}
          multiple
          small
        />
      </div>

      <div className="mb-3">
        <IMSApplicationsFilterGroupTitle>
          Resource Type
        </IMSApplicationsFilterGroupTitle>
        <Select
          name="resourceType"
          selected={selectedResTypes}
          onChange={onSelectFilterChanged(
            'RESOURCE_TYPE',
            'resourceType',
            RESOURCE_TYPES_TO_DISPLAY_MAP,
          )}
          placeholder="Select one more more"
          options={resourceTypeOptions}
          multiple
          small
        />
      </div>

      <div className="mb-3">
        <IMSApplicationsFilterGroupTitle>
          Organizations
        </IMSApplicationsFilterGroupTitle>
        <Select
          name="orgs"
          selected={selectedOrgs}
          onChange={(orgs) => {
            onSelectFilterChanged('ORGS', 'org_name')(orgs)
            resetSupplierOptions()
          }}
          onSearchChange={getSupplierOptions}
          placeholder="Select one more more"
          options={supplierOptions}
          initialOptions={initialSupplierOptions}
          multiple
          small
        />
      </div>
    </div>
  )

  return (
    <div>
      <div className="lg:hidden">
        {filterCount > 0 && clearFilters}
        <div
          data-test="ims-application-filter-modal-toggle"
          className="flex flex-row items-center justify-center p-2 cursor-pointer max-w-min"
          onClick={openFilterModal}
        >
          <i className="fas fa-filter mr-2" />
          <p className="text-base text-text">Filter</p>
        </div>

        <ModalWrapper
          isOpen={filterModalOpen}
          title="Filter applications"
          actions={[
            {
              text: 'Done',
              onClick: closeFilterModal,
              variant: 'start',
            },
          ]}
        >
          {filterContent}
        </ModalWrapper>
      </div>

      <div
        className="bg-background p-2 hidden lg:block"
        style={{ maxWidth: 232 }}
      >
        {filterCount > 0 && clearFilters}
        {filterContent}
      </div>
    </div>
  )
}
