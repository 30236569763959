import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import * as R from 'ramda'

import ITrustedApplication, {
  IFullTrustedApplication,
} from 'domains/trustedApps/models/ITrustedApplication'
import {
  ModalWrapper,
  ToolTip,
  WYSIWYGEditor,
  DatePicker,
  FormInput,
} from 'lib/components'
import { IAddTrustedAppSelection } from 'domains/trustedApps/components/AddTrustedApps/models/IAddTrustedAppSelection'
import FormSelect from 'lib/components/modern/Select/FormSelect'
import useTrustedAppsAdditionalInfo from 'domains/trustedApps/hooks/useTrustedAppsAdditionalInfo'
import FormLabel from 'lib/components/modern/FormLabel'
import { TRUSTED_APPS_RECORD } from 'lib/records/modules/trustedApplications'
import { showToast } from 'lib/utils/toast'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import useTrustedAppsTooluses from 'domains/trustedApps/hooks/useTrustedAppsTooluses'
import { getRecord } from 'lib/records/workflows/getRecord'
import { TRUSTED_APPS_TOOLUSES_DATA_RECORD } from 'lib/records/modules/trustedAppsTooluses'
import { getVocab, amIHed } from 'lib/constants/vocab'
import { utils } from '@ims/1edtech-frontend-common'

type FormValues = {
  technicalNotes: string
  instructionalNotes: string
  coreOrSupplemental: string | undefined
  subjects: string[]
  tooluses: string[]
  grades: string[]
  reviwedByInstructionalDesigners: boolean | undefined
  rosteringMethod: string | undefined
  gradePassbackMethod: string | undefined
  accessMethods: string[]
  securityReview: string | undefined
  learningStandardsAlignment: string | undefined
  downstreamAnalytics: string | undefined
  dataSharing: string | undefined
  licensingCoverage: string[]
  licensingExpirationDate: Date | undefined
  licensingFees: string | undefined
}

const validationSchema = Yup.object().shape({
  technicalNotes: Yup.string(),
  instructionalNotes: Yup.string(),
  coreOrSupplemental: Yup.string().nullable(),
  subjects: Yup.array().of(Yup.string()),
  tooluses: Yup.array().of(Yup.string()),
  grades: Yup.array().of(Yup.string()),
  reviwedByInstructionalDesigners: Yup.mixed().oneOf([
    undefined,
    null,
    true,
    false,
  ]),
  rosteringMethod: Yup.string().nullable(),
  gradePassbackMethod: Yup.string().nullable(),
  accessMethods: Yup.array().of(Yup.string()),
  securityReview: Yup.string().nullable(),
  learningStandardsAlignment: Yup.string().nullable(),
  downstreamAnalytics: Yup.string().nullable(),
  dataSharing: Yup.string().nullable(),
  licensingCoverage: Yup.array().of(Yup.string()),
  licensingExpirationDate: Yup.date().nullable(),
  licensingFees: Yup.string().nullable(),
})

type Props = {
  app?:
    | ITrustedApplication
    | IFullTrustedApplication
    | (IAddTrustedAppSelection & {
        title: string
        application?: any
      })
  isOpen: boolean
  onRequestClose: (saved?: boolean) => void
  onAppSaved?: (app: IAddTrustedAppSelection) => any
}

export default function EditAdditionalInfoModal(props: Props) {
  const [tools = [], toolsPending] = useTrustedAppsTooluses()
  const [additionInfoOptions, optionsPending] = useTrustedAppsAdditionalInfo()

  const appTitle = `${
    props.app?.title ||
    R.pathOr('Application', ['application', 'productName'], props.app)
  }`.trim()

  const onSubmit = async (
    values: FormValues,
    bag: FormikHelpers<FormValues>,
  ) => {
    const app = {
      ...props.app,
      ...values,
      licensingExpirationDate: values.licensingExpirationDate
        ? utils.date
            .getMoment(values.licensingExpirationDate)
            .startOf('day')
            .tz('America/New_York')
            .valueOf()
        : null,
    }
    if (props.onAppSaved) {
      props.onAppSaved(app as IAddTrustedAppSelection)
      bag.setSubmitting(false)
      props.onRequestClose(true)
    } else if (props.app) {
      const success = await updateRecord(
        TRUSTED_APPS_RECORD,
        (props.app as ITrustedApplication).id,
        app,
        true,
      )
      // Refetch TRUSTED_APPS_TOOLUSES_DATA_RECORD which is used in Advanced Search
      await getRecord(TRUSTED_APPS_TOOLUSES_DATA_RECORD, 1)
      bag.setSubmitting(false)
      if (success) {
        props.onRequestClose(true)
        showToast(
          'start',
          `Successfully saved additional info for ${appTitle}.`,
        )
      } else showToast('error', 'Failed to update app. Please try again.')
    }
  }

  const initialValues = {
    technicalNotes: props.app?.technicalNotes || '',
    instructionalNotes: props.app?.instructionalNotes || '',
    coreOrSupplemental: props.app?.coreOrSupplemental,
    subjects: props.app?.subjects || [],
    tooluses: props.app?.tooluses || [],
    grades: props.app?.grades || [],
    reviwedByInstructionalDesigners: props.app?.reviwedByInstructionalDesigners,
    rosteringMethod: props.app?.rosteringMethod,
    gradePassbackMethod: props.app?.gradePassbackMethod,
    accessMethods: props.app?.accessMethods || [],
    securityReview: props.app?.securityReview,
    learningStandardsAlignment: props.app?.learningStandardsAlignment,
    downstreamAnalytics: props.app?.downstreamAnalytics,
    dataSharing: props.app?.dataSharing,
    licensingCoverage: props.app?.licensingCoverage || [],
    licensingExpirationDate: props.app?.licensingExpirationDate
      ? utils.date.getMoment(props.app.licensingExpirationDate).toDate()
      : undefined,
    licensingFees: props.app?.licensingFees,
  }

  const pending = optionsPending || toolsPending

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formProps) => {
        return (
          <ModalWrapper
            isOpen={props.isOpen}
            title={`${appTitle} - Additional Info`}
            actions={
              pending
                ? []
                : [
                    {
                      text: 'Save',
                      variant: 'start',
                      extra: { type: 'submit' },
                      onClick: async () => {
                        await formProps.submitForm()
                        formProps.resetForm()
                      },
                    },
                    {
                      text: 'Cancel',
                      variant: 'neutral',
                      onClick: () => {
                        props.onRequestClose()
                        formProps.resetForm()
                      },
                    },
                  ]
            }
            pending={formProps.isSubmitting}
            maxWidth={['100%', 820, 900]}
          >
            <div className="flex flex-col space-y-3 bg-white pb-16">
              <div>
                <FormLabel htmlFor="instructionalNotes">
                  Technical Notes
                </FormLabel>
                <WYSIWYGEditor
                  name="technicalNotes"
                  value={formProps.values.technicalNotes}
                  onChange={(value: string) =>
                    formProps.setFieldValue('technicalNotes', value)
                  }
                  placeholder="Technical Notes, Resource URLs, etc."
                />
              </div>

              <div>
                <FormLabel htmlFor="instructionalNotes">
                  Instructional Notes
                </FormLabel>
                <WYSIWYGEditor
                  name="instructionalNotes"
                  value={formProps.values.instructionalNotes}
                  onChange={(value: string) =>
                    formProps.setFieldValue('instructionalNotes', value)
                  }
                  placeholder="Instructional Notes, Resource URLs, etc."
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col space-y-3">
                  <FormSelect
                    label="Core vs Supplemental Curriculum"
                    name="coreOrSupplemental"
                    selected={formProps.values.coreOrSupplemental}
                    onChange={(value: any) =>
                      formProps.setFieldValue('coreOrSupplemental', value)
                    }
                    placeholder={`Select${
                      additionInfoOptions.coreOrSupplemental.multiple
                        ? ''
                        : ' one'
                    }${
                      additionInfoOptions.coreOrSupplemental.extensible
                        ? ' or add your own'
                        : ''
                    }`}
                    options={additionInfoOptions.coreOrSupplemental.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.coreOrSupplemental.multiple}
                    extensible={
                      additionInfoOptions.coreOrSupplemental.extensible
                    }
                  />
                  <FormSelect
                    label={getVocab('subject', true, true)}
                    name="subjects"
                    selected={formProps.values.subjects}
                    onChange={(value: any) =>
                      formProps.setFieldValue('subjects', value)
                    }
                    placeholder={`Select${
                      additionInfoOptions.subjects.multiple ? '' : ' one'
                    }${
                      additionInfoOptions.subjects.extensible
                        ? ' or add your own'
                        : ''
                    }`}
                    options={additionInfoOptions.subjects.adminValues
                      .map((s) => ({ label: s, value: s }))
                      .sort((a, b) => a.label.localeCompare(b.label))}
                    multiple={additionInfoOptions.subjects.multiple}
                    extensible={additionInfoOptions.subjects.extensible}
                  />
                  <FormSelect
                    label={getVocab('toolUse', true, false)}
                    name="tooluses"
                    selected={formProps.values.tooluses}
                    onChange={(value: any) =>
                      formProps.setFieldValue('tooluses', value)
                    }
                    placeholder={`Select${
                      additionInfoOptions.tooluses.multiple ? '' : ' one'
                    }${
                      additionInfoOptions.tooluses.extensible
                        ? ' or add your own'
                        : ''
                    }`}
                    options={R.uniq([
                      ...additionInfoOptions.tooluses.adminValues.map((s) => ({
                        label: s,
                        value: s,
                      })),
                      ...tools.map((s) => ({
                        label: s,
                        value: s,
                      })),
                    ]).sort((a, b) => a.label.localeCompare(b.label))}
                    multiple={additionInfoOptions.tooluses.multiple}
                    extensible={additionInfoOptions.tooluses.extensible}
                  />
                  {amIHed() ? (
                    <FormSelect
                      label={getVocab('grade', true, true)}
                      name="grades"
                      selected={formProps.values.grades}
                      onChange={(value: any) =>
                        formProps.setFieldValue('grades', value)
                      }
                      placeholder={`Select${
                        additionInfoOptions.grades.multiple ? '' : ' one'
                      }${
                        additionInfoOptions.grades.extensible
                          ? ' or add your own'
                          : ''
                      }`}
                      options={additionInfoOptions.grades.adminValues.map(
                        (s) => ({
                          label: s,
                          value: s,
                        }),
                      )}
                      multiple={additionInfoOptions.grades.multiple}
                      extensible={additionInfoOptions.grades.extensible}
                    />
                  ) : (
                    <FormSelect
                      label={getVocab('grade', true, true)}
                      labelExtra={
                        <ToolTip id="grades" tip="Learn more" delay={250}>
                          <a
                            href="https://ceds.ed.gov/CEDSElementDetails.aspx?TermId=7100"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="px-1 text-base text-gray-500 cursor-pointer hover:text-complete"
                            data-test="Grades Info"
                          >
                            <i className="fas fa-question-circle" />
                          </a>
                        </ToolTip>
                      }
                      name="grades"
                      selected={formProps.values.grades}
                      onChange={(value: any) =>
                        formProps.setFieldValue('grades', value)
                      }
                      placeholder={`Select${
                        additionInfoOptions.grades.multiple ? '' : ' one'
                      }${
                        additionInfoOptions.grades.extensible
                          ? ' or add your own'
                          : ''
                      }`}
                      options={additionInfoOptions.grades.adminValues.map(
                        (s) => ({
                          label: s,
                          value: s,
                        }),
                      )}
                      multiple={additionInfoOptions.grades.multiple}
                      extensible={additionInfoOptions.grades.extensible}
                    />
                  )}
                  <FormSelect
                    label={`Has app been reviewed by instructional designers in the ${getVocab(
                      'district',
                    )}?`}
                    name="reviwedByInstructionalDesigners"
                    selected={formProps.values.reviwedByInstructionalDesigners}
                    onChange={(value: any) =>
                      formProps.setFieldValue(
                        'reviwedByInstructionalDesigners',
                        value,
                      )
                    }
                    placeholder="Select one"
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                  />
                  <FormSelect
                    label="Who is covered by this license?"
                    name="licensingCoverage"
                    selected={formProps.values.licensingCoverage}
                    onChange={(value: any) => {
                      formProps.setFieldValue('licensingCoverage', value)
                    }}
                    placeholder="Select or add your own"
                    options={additionInfoOptions.coverage.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.coverage.multiple}
                    extensible={additionInfoOptions.coverage.extensible}
                  />
                  <FormInput
                    label="Licensing Fees"
                    name="licensingFees"
                    placeholder="e.g. $100.00"
                    value={formProps.values.licensingFees || ''}
                    handleChange={formProps.handleChange}
                    valueDataTest="licensing-fee-field"
                  />
                  <FormLabel htmlFor="licensingExpirationDate">
                    Licensing Expiration Date
                  </FormLabel>
                  <DatePicker
                    date={formProps.values.licensingExpirationDate!}
                    handleChange={(value: Date) => {
                      formProps.setFieldValue('licensingExpirationDate', value)
                    }}
                    isClearable={true}
                    openToDate={utils.date.getMoment().toDate()}
                    data-test="licensing-expiration-date-input"
                  />
                </div>

                <div className="flex flex-col space-y-3">
                  <FormSelect
                    label={`How is this ${getVocab('rostered')}?`}
                    name="rosteringMethod"
                    selected={formProps.values.rosteringMethod}
                    onChange={(value: any) =>
                      formProps.setFieldValue('rosteringMethod', value)
                    }
                    placeholder={`Select${
                      additionInfoOptions.rosteringMethod.multiple ? '' : ' one'
                    }${
                      additionInfoOptions.rosteringMethod.extensible
                        ? ' or add your own'
                        : ''
                    }`}
                    options={additionInfoOptions.rosteringMethod.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.rosteringMethod.multiple}
                    extensible={additionInfoOptions.rosteringMethod.extensible}
                  />
                  <FormSelect
                    label="Does this send Grade data back?"
                    name="gradePassbackMethod"
                    selected={formProps.values.gradePassbackMethod}
                    onChange={(value: any) =>
                      formProps.setFieldValue('gradePassbackMethod', value)
                    }
                    placeholder={`Select${
                      additionInfoOptions.gradePassbackMethod.multiple
                        ? ''
                        : ' one'
                    }${
                      additionInfoOptions.gradePassbackMethod.extensible
                        ? ' or add your own'
                        : ''
                    }`}
                    options={additionInfoOptions.gradePassbackMethod.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.gradePassbackMethod.multiple}
                    extensible={
                      additionInfoOptions.gradePassbackMethod.extensible
                    }
                  />
                  <FormSelect
                    label="How do students and faculty access the app?"
                    name="accessMethods"
                    selected={formProps.values.accessMethods}
                    onChange={(value: any) =>
                      formProps.setFieldValue('accessMethods', value)
                    }
                    placeholder={`Select${
                      additionInfoOptions.accessMethods.multiple ? '' : ' one'
                    }${
                      additionInfoOptions.accessMethods.extensible
                        ? ' or add your own'
                        : ''
                    }`}
                    options={additionInfoOptions.accessMethods.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.accessMethods.multiple}
                    extensible={additionInfoOptions.accessMethods.extensible}
                  />
                  <FormSelect
                    label="Has institution performed security review?"
                    name="securityReview"
                    selected={formProps.values.securityReview}
                    onChange={(value: any) =>
                      formProps.setFieldValue('securityReview', value)
                    }
                    placeholder={`Select${
                      additionInfoOptions.securityReview.multiple ? '' : ' one'
                    }${
                      additionInfoOptions.securityReview.extensible
                        ? ' or add your own'
                        : ''
                    }`}
                    options={additionInfoOptions.securityReview.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.securityReview.multiple}
                    extensible={additionInfoOptions.securityReview.extensible}
                  />
                  <FormSelect
                    label="Does this app align activities to an academic standard?"
                    name="learningStandardsAlignment"
                    selected={formProps.values.learningStandardsAlignment}
                    onChange={(value: any) =>
                      formProps.setFieldValue(
                        'learningStandardsAlignment',
                        value,
                      )
                    }
                    placeholder={`Select${
                      additionInfoOptions.learningStandardsAlignment.multiple
                        ? ''
                        : ' one'
                    }${
                      additionInfoOptions.learningStandardsAlignment.extensible
                        ? ' or add your own'
                        : ''
                    }`}
                    options={additionInfoOptions.learningStandardsAlignment.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={
                      additionInfoOptions.learningStandardsAlignment.multiple
                    }
                    extensible={
                      additionInfoOptions.learningStandardsAlignment.extensible
                    }
                  />
                  <FormSelect
                    label="Does this send app data for downstream learning analytics?"
                    name="downstreamAnalytics"
                    selected={formProps.values.downstreamAnalytics}
                    onChange={(value: any) => {
                      formProps.setFieldValue('downstreamAnalytics', value)
                    }}
                    placeholder={`Select${
                      additionInfoOptions.downstreamAnalytics.multiple
                        ? ''
                        : ' one'
                    }${
                      additionInfoOptions.downstreamAnalytics.extensible
                        ? ' or add your own'
                        : ''
                    }`}
                    options={additionInfoOptions.downstreamAnalytics.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.downstreamAnalytics.multiple}
                    extensible={
                      additionInfoOptions.downstreamAnalytics.extensible
                    }
                  />
                  <FormSelect
                    label="Is there a data sharing agreement on file between the institution and vendor?"
                    name="dataSharing"
                    selected={formProps.values.dataSharing}
                    onChange={(value: any) => {
                      formProps.setFieldValue('dataSharing', value)
                    }}
                    placeholder={`Select${
                      additionInfoOptions.dataSharing.multiple ? '' : ' one'
                    }${
                      additionInfoOptions.dataSharing.extensible
                        ? ' or add your own'
                        : ''
                    }`}
                    options={additionInfoOptions.dataSharing.adminValues.map(
                      (s) => ({ label: s, value: s }),
                    )}
                    multiple={additionInfoOptions.dataSharing.multiple}
                    extensible={additionInfoOptions.dataSharing.extensible}
                  />
                </div>
              </div>
            </div>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
