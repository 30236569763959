export const GREEN_REPORT_STATUS = 'GREEN'
export const AMBER_REPORT_STATUS = 'AMBER'
export const RED_REPORT_STATUS = 'RED'
export const UNKOWN_REPORT_STATUS = 'UNKNOWN'
export const GRAY_REPORT_STATUS = 'GRAY'
export const UNSUPPORTED_REPORT_STATUS = 'UNSUPPORTED'
export const INDETERMINATE_REPORT_STATUS = 'INDETERMINATE'

export type ReportStatus =
  | 'GREEN'
  | 'AMBER'
  | 'RED'
  | 'UNKNOWN'
  | 'GRAY'
  | 'UNSUPPORTED'
  | 'INDETERMINATE'

interface IStatusMap {
  [s: string]: any
}

export const STATUS_TO_TEXT_COLOR_MAP: IStatusMap = {
  [GREEN_REPORT_STATUS]: 'text-success',
  [AMBER_REPORT_STATUS]: 'text-warningAlt',
  [RED_REPORT_STATUS]: 'text-error',
  [UNKOWN_REPORT_STATUS]: 'text-warning',
  [GRAY_REPORT_STATUS]: 'text-neutral',
  [UNSUPPORTED_REPORT_STATUS]: 'text-reportDisabled',
  [INDETERMINATE_REPORT_STATUS]: 'text-error',
}

export const STATUS_TO_BADGE_VARIANT_MAP: IStatusMap = {
  [GREEN_REPORT_STATUS]: 'success',
  [AMBER_REPORT_STATUS]: 'warningAlt',
  [RED_REPORT_STATUS]: 'error',
  [UNKOWN_REPORT_STATUS]: 'warning',
  [GRAY_REPORT_STATUS]: 'neutral',
  [UNSUPPORTED_REPORT_STATUS]: 'reportDisabled',
  [INDETERMINATE_REPORT_STATUS]: 'error',
}

export const STATUS_TO_LIGHT_VARIANT_MAP: IStatusMap = {
  [GREEN_REPORT_STATUS]: 'reportSuccessLight',
  [AMBER_REPORT_STATUS]: 'reportAmberLight',
  [RED_REPORT_STATUS]: 'reportAlertLight',
  [UNKOWN_REPORT_STATUS]: 'neutral',
  [GRAY_REPORT_STATUS]: 'neutral',
  [UNSUPPORTED_REPORT_STATUS]: 'text',
  [INDETERMINATE_REPORT_STATUS]: 'reportAlertLight',
}

export const STATUS_TO_BADGE_ICON_MAP: IStatusMap = {
  [GREEN_REPORT_STATUS]: 'fas fa-check-circle',
  [AMBER_REPORT_STATUS]: 'fas fa-check-circle',
  [RED_REPORT_STATUS]: 'fas fa-exclamation-circle',
  [UNKOWN_REPORT_STATUS]: 'fas fa-question-circle',
  [GRAY_REPORT_STATUS]: 'fas fa-minus',
  [UNSUPPORTED_REPORT_STATUS]: 'fa fa-times-circle',
  [INDETERMINATE_REPORT_STATUS]: 'fas fa-question-circle',
}

export const STATUS_TO_DISPLAY_MAP: IStatusMap = {
  [GREEN_REPORT_STATUS]: 'Fully Compatible',
  [AMBER_REPORT_STATUS]: 'Alerts',
  [RED_REPORT_STATUS]: 'Incompatible',
  [GRAY_REPORT_STATUS]: 'N/A',
  [UNSUPPORTED_REPORT_STATUS]: 'Unsupported',
  [INDETERMINATE_REPORT_STATUS]: 'Indeterminate',
}

export const CHARACTERIZATION_STATUS_TO_DISPLAY_MAP: IStatusMap = {
  [GREEN_REPORT_STATUS]: '',
  [AMBER_REPORT_STATUS]: 'ALERT',
  [RED_REPORT_STATUS]: 'CRITICAL',
  INFO: 'NOTES',
  [INDETERMINATE_REPORT_STATUS]: 'INDETERMINATE',
}

export const CHARACTERIZATION_STATUS_TO_PRINT_DISPLAY_MAP: IStatusMap = {
  [GREEN_REPORT_STATUS]: '',
  [AMBER_REPORT_STATUS]: 'ALERTS',
  [RED_REPORT_STATUS]: 'FAILED',
  [INDETERMINATE_REPORT_STATUS]: 'INDETERMINATE',
}

export const COMPARISON_STATUS_TO_PRINT_DISPLAY_MAP: IStatusMap = {
  [GREEN_REPORT_STATUS]: '',
  [AMBER_REPORT_STATUS]: 'ALERTS',
  [RED_REPORT_STATUS]: 'INCOMPATIBLE',
  [INDETERMINATE_REPORT_STATUS]: 'INDETERMINATE',
}

export const STATUS_TO_COMPARISON_NUMBER_MAP: IStatusMap = {
  [GREEN_REPORT_STATUS]: 0,
  [AMBER_REPORT_STATUS]: 1,
  [RED_REPORT_STATUS]: 2,
  [UNKOWN_REPORT_STATUS]: 3,
  [GRAY_REPORT_STATUS]: 4,
  [UNSUPPORTED_REPORT_STATUS]: 5,
  [INDETERMINATE_REPORT_STATUS]: 6
}
