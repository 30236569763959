import React from 'react'
import clsx from 'clsx'

import { Dropdown } from 'lib/components'
import {
  TrustedApplicationStatusesType,
  TRUSTED_APPS_DENIED_STATUS,
  TRUSTED_APPS_PREFERRED_STATUS,
  TRUSTED_APPS_APPROVED_STATUS,
  TRUSTED_APPS_APPROVED_LIMITED_STATUS,
  TRUSTED_APPS_UNDER_REVIEW_STATUS,
  TRUSTED_APPS_REVIEWED_STATUS,
  TRUSTED_APPS_UNRATED_STATUS,
} from 'domains/trustedApps/models/ITrustedApplication'
import TrustedAppStatusChip from 'domains/trustedApps/components/TrustedAppDetails/TrustedAppStatusChip'

interface IStatusItemProps {
  logo?: string
  status: TrustedApplicationStatusesType
  onChange: (status: TrustedApplicationStatusesType) => () => any
  noBorder?: boolean
}
function StatusItem(props: IStatusItemProps) {
  return (
    <div
      className={clsx('p-1 cursor-pointer hover:bg-gray-200', {
        border: !props.noBorder,
      })}
      onClick={props.onChange(props.status)}
      data-test={`app-status-${props.status}`}
    >
      <TrustedAppStatusChip status={props.status} logo={props.logo} small />
    </div>
  )
}

interface IProps {
  applicationId: number
  status: TrustedApplicationStatusesType
  onStatusChange: (
    applicationId: number,
    status: TrustedApplicationStatusesType,
  ) => any
  parentOrgId?: number
  logo?: string
}

export default function TrustedAppsStatusSelector(props: IProps) {
  const onChange = (status: TrustedApplicationStatusesType) => () =>
    props.onStatusChange(props.applicationId, status)

  return (
    <Dropdown
      target={
        <div
          data-test={`selected-app-status-selector-${props.applicationId}`}
          className="flex flex-col justify-center w-full h-full"
        >
          <TrustedAppStatusChip status={props.status} small />
        </div>
      }
      showChevron={true}
    >
      <div className="bg-white shadow-md">
        <StatusItem
          status={TRUSTED_APPS_PREFERRED_STATUS}
          onChange={onChange}
        />
        <StatusItem status={TRUSTED_APPS_APPROVED_STATUS} onChange={onChange} />
        <StatusItem
          status={TRUSTED_APPS_APPROVED_LIMITED_STATUS}
          onChange={onChange}
        />
        <StatusItem status={TRUSTED_APPS_REVIEWED_STATUS} onChange={onChange} />
        <StatusItem
          status={TRUSTED_APPS_UNDER_REVIEW_STATUS}
          onChange={onChange}
        />
        <StatusItem
          status={TRUSTED_APPS_DENIED_STATUS}
          onChange={onChange}
          noBorder={true}
        />
        <StatusItem
          status={TRUSTED_APPS_UNRATED_STATUS}
          onChange={onChange}
          noBorder={true}
        />
      </div>
    </Dropdown>
  )
}
